#program-finder-template,
#class-finder-template {
	header.hero-image {
		margin-bottom: 0;
	}
	section.three-column {
		.container:after {
			display: none;
		}
	}
	section.generic-block-cta {
		margin-bottom: 80px;
		margin-top: 40px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 100px;				
		}
	}
	section .container {
		max-width: 1130px;
   		margin: 0 auto;
	}
}
section.program-finder,
section.class-finder {
	.container {
		position: relative;
		max-width: 1130px;
		margin: 0 auto;
	}
	.program-finder-results,
	.class-finder-results {
		@extend .bordered;
	}
	h3.title {
		@include fontsize(12px);
		color: #000;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 0.67px;
		line-height: 1.33;
		margin: 0 10px 0 0;
		padding: 0;
		text-transform: uppercase;

		@media screen and (min-width: $break-medium) {
			@include fontsize(16px);
		}

		@media screen and (min-width: $break-large) {
			@include fontsize(18px);
		}
	}
	
	.filter-control-wrapper {
		background: $color-off-white;
		position: relative;
	}

	.filter-controls {
		overflow: hidden;
		&.collapsed {
			.jump-to-results {
				display: none;
				@media screen and (min-width: $break-medium) {
					display: inline-block;
				}
			}
		}

		@media screen and (min-width: $break-medium) {
			padding: 0;
		}

		@media screen and (min-width: $break-large) {
		}
		h2 {
			margin-top: 0;
		}
		a {
			border: none;
			box-shadow: none;
			&:hover {
				background-color: transparent;
			}
		}
	}

	.filter-control {
		color: $color-grey-2;
		text-transform: uppercase;
		white-space: nowrap;
		@include fontsize(14px);
		padding: 8px 0;
		border-bottom: none;
		box-shadow: none;
				
		@media screen and (min-width: $break-medium) {
			display: inline-block;
			margin: 0 14px 0 3px;
		}
		&:hover {
			border-bottom: none;
			box-shadow: none;
			color: $color-blue-2;
		}
		&::before {
			content: none;
		}


		.icon {
			color: $color-blue-2;
			padding-right: 9px;
			@include fontsize(22px);
			transform: 0.3s color ease-out;

			@media screen and (min-width: $break-large) {
				@include fontsize(24px);
			}
		}

		span {
			vertical-align: middle;
		}
	}

	.filter-control-label {
		display: none;
		
		&-mobile {
			display: inline-block;
		}

		@media screen and (min-width: $break-large) {
			display: inline-block;
			@include fontsize(16px);
			
			&-mobile {
				display: none;
			}
		}
	}

	.jump-to-results {
		position: absolute;
		top: 84px;
		left: 0px;
		right: 0px;
		width: 100%;
		border: 2px solid #dbdbdb;
		@include fontsize(14px);
		border-left: none;
		border-right: none;
		padding: 14px 16px;

		.filter-control-label {
			display: inline-block;
		}

		@media screen and (min-width: $break-medium) {
			position: absolute;
			border: none;
			padding: 8px 0;
			width: auto;
			top: 24px;
			right: 232px;
			left: auto;
		}
		
		@media screen and (min-width: $break-large) {
			position: absolute;
			border: none;
			padding: 8px 0;
			width: auto;
			top: 52px;
			right: 402px;
			left: auto;
		}
	}

	.hide-button {
		right: 8px;
		min-width: 68px;

		@media screen and (min-width: $break-medium) {
			right: 50px;
		}

		@media screen and (min-width: $break-large) {
			min-width: 130px;
			right: 35px;
		}
	}

	.reset-button {
		right: 96px;
		
		@media screen and (min-width: $break-medium) {
			right: 141px;
		}
		@media screen and (min-width: $break-large) {
			right: 210px;
		}
	}
	
	.hide-button,
	.reset-button {
		position: absolute;
		top: 23px;
		@media screen and (min-width: $break-medium) {
			top: 24px;
		}
		@media screen and (min-width: $break-large) {
			top: 52px;
		}
	}

	.full-filter {	
		@media screen and (min-width: $break-medium) {
			margin: 0 -10px;
		}
		h3 {
			flex: 1 1 100%;
			width: 100%;
		}
	}

	.half-filter {
		@media screen and (min-width: $break-medium) {
			display: inline-block;
			flex: 1 0 auto;
			width: calc(48% - 10px);
			margin: 0 10px;
		}
	}

	.article-wrapper {
		width: 100%;

		@media screen and (min-width: $break-medium) {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -10px;
			width: calc(100% + 20px);
		}
		
		@media screen and (min-width: $break-large) {
			margin: -20px -25px;
			width: calc(100% + 50px);
		}
	}

	.program-listing,
	.class-listing {
		position: relative;
		background: $color-off-white;
		padding: 18px 18px 18px 52px;
		margin: 10px auto;
		
		@media screen and (min-width: $break-medium) {
			width: calc(50% - 20px);
			margin: 5px 10px;
			padding: 18px 18px 42px 48px;
		}

		@media screen and (min-width: $break-large) {
			margin: 20px 25px;
			padding: 30px 10px 50px 62px;
			width: calc(50% - 50px);
		}
		span.icon {
			position: absolute;
			left: 20px;
			top: 25px;
			@include fontsize(18px);

			@media screen and (min-width: $break-medium) {
				left: 16px;
				top: 22px;
			}

			@media screen and (min-width: $break-large) {
				@include fontsize(18px);
				left: 30px;
				top: 36px;
			}
		}
	}
	
	h3.area-of-interest {
		@include fontsize(20px);
		line-height: 1.5;
		margin: 0 auto;

		a {
			box-shadow: none;
			border: none;
			transition: 0.3s;
			&:hover {
				background: transparent;
			}
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(28px);
			letter-spacing: -0.51px;
			line-height: 1.07;
		}
	}

	p.degree-type {
		@include fontsize(16px);
		line-height: 2;
		margin: 0;

		@media screen and (min-width: $break-large) {
			@include fontsize(24px);
		}
	}

	h2 {
		flex: 1 1 100%;
		width: 100%;
		margin: 75px auto 16px auto;
		//margin-top: 0;
		@media screen and (min-width: $break-medium) {
			margin-bottom: 32px;
		}
		@media screen and (min-width: $break-large) {
			margin: 118px auto 32px auto;
		}
	}

	a.button {
		display: block;
		margin: 30px auto;
		max-width: 240px;
		text-align: center;
		
		@media screen and (min-width: $break-medium) {
			flex: 1 1 50%;
			align-self: center;
		}

		@media screen and (min-width: $break-large) {
			margin: 60px auto 0;
			max-width: 280px;
		}
	}

	.icon-arts {
		color: $color-red;
	}

	.icon-humanities {
		color: $color-green;
	}

	.icon-business {
		color: $color-purple;
	}
	.icon-lifescience {
		color: $color-yellow;
	}
	.icon-stem {
		color: $color-blue-3;
	}
}
section.class-finder {
	#area-of-interest-filter ul {
		@media screen and (min-width: $break-medium) {
			@include clearfix();
			margin-left: -10px;
			margin-right: -10px;							
		}
		li {
			@media screen and (min-width: $break-medium) {							
				width: calc(50% - 20px);
				margin: 10px;	
				float: left;
			}
			label {
		display: block;
		cursor: pointer;
		padding: 10px 15px 12px 45px;
		transition: background 0.3s ease-out;
		
		@media screen and (min-width: $break-medium) {
			padding: 15px 15px 15px 48px;
		}

		&:hover,
		&.toggled {
			background: $color-yellow;
			.icon {
				color: #000;
			}
		}

		@media screen and (min-width: $break-large) {
			@include fontsize(16px);
			padding: 20px 18px 20px 50px;
		}

		.icon {
			@include fontsize(24px);
			padding-right: 11px;
			position: absolute;
			top: 50%;
			left: 50px;
			transform: translateY(-50%);
			@media screen and (min-width: $break-large) {
				left: 54px;
			}
		}

		span {
			display: inline-block;
		}

		[type="checkbox"] + span {
			padding: 10px 28px 10px 0;
			width: 100%;

			@media screen and (min-width: $break-medium) {
				padding: 0;
			}
			
			&:before {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
				transition: 0.3 all ease-out;
				display: block;
				content: '';
				background: $color-white;
				border: 1px solid #d9d9d9;
				border-radius: 2px;
				height: 14px;
				width: 14px;
			}
			.icon-checkmark {
				display: none;
			}
		}

		[type="checkbox"]:checked + span {
			&:before {
				background: #000;
				border-color: #000;
				color: #fff;
			}
			.icon-checkmark {
				color: $color-white;
				display: block;
				position: absolute;
				left: 18px;
				top: 51%;
				transform: translateY(-50%);
				@include fontsize(9px);
			}
		}

		[type="checkbox"]:not(:active):focus + span {
			&:before {
				outline: 1px solid $color-grey-2;
			}
		}
	}
		}
	}
}
#filter-section {
	
	h2 {
		padding: 27px 0 0 0;
		min-height: 70px;

		@media screen and (min-width: $break-medium) {
			display: inline-block;
			padding: 5px 0 0 0;
			margin: 25px 0;
			vertical-align: middle;
			min-height: auto;
		}
		@media screen and (min-width: $break-large) {
			margin: 45px 0 35px 0;
		}
	}

	ul {
		flex: 1 1 100%;
		margin: 0 0 15px 0;
		padding: 0;
		width: 100%;

		@media screen and (min-width: $break-large) {
			margin: 0 0 25px 0;
		}
		
		li {
			border-radius: 2px;
			background: $color-white;
			margin: 8px auto;
			padding: 0;
			@include fontsize(14px);
			font-weight: 500;
			line-height: 1;
			letter-spacing: 0.7px;

			&:before {
				content: none;
				background: none;
			}

			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				margin: 10px auto;
			}
		}
	}
	
	label {
		display: block;
		cursor: pointer;
		padding: 10px 15px 12px 52px;
		transition: background 0.3s ease-out;
		
		@media screen and (min-width: $break-medium) {
			padding: 15px 15px 15px 48px;
		}

		&:hover,
		&.toggled {
			background: $color-yellow;
			.icon {
				color: #000;
			}
		}

		@media screen and (min-width: $break-large) {
			@include fontsize(16px);
			padding: 20px 18px 20px 50px;
		}

		.icon {
			@include fontsize(24px);
			padding-right: 11px;
			position: absolute;
			top: 50%;
			left: 50px;
			transform: translateY(-50%);
			@media screen and (min-width: $break-large) {
				left: 54px;
			}
		}

		span {
			display: inline-block;
		}

		[type="checkbox"] + span {
			padding: 10px 28px 10px 0;
			width: 100%;

			@media screen and (min-width: $break-medium) {
				padding: 0;
			}
			
			&:before {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
				transition: 0.3 all ease-out;
				display: block;
				content: '';
				background: $color-white;
				border: 1px solid #d9d9d9;
				border-radius: 2px;
				height: 14px;
				width: 14px;
			}
			.icon-checkmark {
				display: none;
			}
		}

		[type="checkbox"]:checked + span {
			&:before {
				background: #000;
				border-color: #000;
				color: #fff;
			}
			.icon-checkmark {
				color: $color-white;
				display: block;
				position: absolute;
				left: 18px;
				top: 51%;
				transform: translateY(-50%);
				@include fontsize(9px);
			}
		}

		[type="checkbox"]:not(:active):focus + span {
			&:before {
				outline: 1px solid $color-grey-2;
			}
		}
	}
}
#area-of-interest-filter {
	margin: 0;

	[type="checkbox"] + span.area-of-interest {
		padding: 14px 0px 9px 40px;
		
		@media screen and (min-width: $break-medium) {
			padding: 5px 30px 5px 45px;
		}
		@media screen and (min-width: $break-large) {
			padding: 10px 30px 5px 30px;
		}
	}

	label {
		@media screen and (min-width: $break-large) {
		    padding: 12px 18px 13px 65px;
		}
	}
}

#filter-containers {
	padding-top: 90px;

	&.collapsed {
		padding-top: 0;
	}
	@media screen and (min-width: $break-medium) {
		padding-top: 0;
	}
}
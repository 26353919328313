
/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 250px;
}

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}

/* Button */
.selectboxit-container .selectboxit {
  width: 220px; /* Width of the dropdown button */    
  max-width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  position: relative;
}

/* Height and Vertical Alignment of Text */
.selectboxit-container span {
  height: 40px; /* Height of the drop down */
  line-height: 30px; /* Vertically positions the drop down text */
}
.selectboxit-option-anchor {
	display: inline-block !important;
	line-height: 1.3;
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
  cursor: default;
}

/* Button Text */
.selectboxit-text {
	text-indent: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	float: left;
	padding-top: 5px;
	padding-right: 38px;
	padding-left: 10px;
	max-width: 100% !important;
	width: 100%;
}

/* Options List */
.selectboxit-container .selectboxit-options {
  box-sizing: border-box;
  box-sizing: content-box\9;
  min-width: 100%;  /* Minimum Width of the dropdown list box options */
  margin: 0;
  padding: 5px 0 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 9999999999999;
  text-align: left;
  box-shadow: none;
  margin: 0;
  li {
	padding-left: 0;
	list-style: none;
	padding-left: 10px;
	transition: 0.3s;
	margin-bottom: 0 !important;
	&:before {
		display: none;
	}
	&[data-disabled="true"] {
		display: none;
	}
	a {
		font-weight: normal !important;
		padding-top: 5px;
		padding-bottom: 5px;
		border: none;
		box-shadow: none;
		&:hover {
			background-color: transparent;
		}
		&:before {
			display: none;
		}
	}
  }
}

/* Individual options */
 .selectboxit-option .selectboxit-option-anchor {
  padding: 0;
  text-indent: 0 !important;
}

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  text-indent: 5px; /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}

/* The first Drop Down option */
.selectboxit-option-first {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default;
}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 30px;
  position: absolute;
  right: 0;
}
.selectboxit-arrow {
	font-size: 12px;
}

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  right: 0;
  left: 0;
}

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
  display: none !important;
}

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
	background-color: #f5f5f5;
	background-repeat: repeat-x;
	border: 1px solid #cccccc;
	border-color: #e6e6e6 #e6e6e6 #bfbfbf;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	border-bottom-color: #b3b3b3;
	padding: 10px 14px 8px;
	margin-bottom: 20px;
	border: 1px solid $color-grey-6;
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
}

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
  color: inherit;
  background-color: #fff;
}

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus {
  color: inherit;
  text-decoration: none;
  background-position: 0 -15px;
}

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.selectboxit-list .selectboxit-option-anchor {
  color: #333333;
  white-space: pre-wrap;
}

.selectboxit-list > .selectboxit-focus {
  background-color: #efefef;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}

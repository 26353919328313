body.section-nav-open {
	overflow: hidden;
	#section-nav-overlay {
		opacity: 0.41;
		width: 100%;
		height: 100vh;
	}
}
#section-nav-overlay {
	background-color: $color-blue;
	opacity: 0;
	position: absolute;
	top: 68px;
	left: 0;
	transition: opacity 0.3s;
	@media screen and (max-width: $break-medium - 1px) {
		display: none !important;				
	}
	@media screen and (min-width: $break-large) {
		top: 81px;				
	}
}
.section-navigation-wrapper {
	@include clearfix();
	color: #fff;
	@media screen and (min-width: $break-medium) {
		background-color: rgba(58,58,58,0.7)
	}
	@media screen and (min-width: $break-large) {
		display: flex;
		flex-direction: row;				
	}
}
.section-navigation {
	transition: 0.3s;
	@media screen and (min-width: $break-medium) {
		@include clearfix();
		position: relative;			
	}
	@media screen and (max-width: $break-medium - 1px) {
		width: 100%;				
	}
	&.expanded {
		color: $color-blue-2;
		#section-nav-toggle {	
			background-color: #fff;
			color: $color-blue-2;	
			.icon-section-expand:before {
				content: "\e90a";
			}
		}
		.section-nav-wrapper {
			animation: slide-right 0.3s ease forwards;
		}
	}
	#section-nav-toggle {
		@include fontsize(12px);
		background: rgba(0, 0, 0, 0.54);
		color: #fff;
		position: relative;
		padding-right: 30px;
		padding-left: 10px;
		text-align: left;
		height: 52px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: 500;
		z-index: 2;
		@media screen and (min-width: $break-medium) {
			border-right: 1px solid #3d3d3d;
			min-width: 227px;				
		}
		@media screen and (min-width: $break-large) {
			min-width: 240px;
			padding-left: 15px;
   			text-align: center;
   			padding-right: 42px;		
		}
		@media screen and (max-width: $break-medium - 1px) {
			width: 100%;				
		}
		.icon-section-expand {
			font-size: 10px;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translate(0, -50%);
			@media screen and (min-width: $break-large) {
			    font-size: 12px;   
			    right: 23px;				
			}
		}
	}
	.section-nav-wrapper {
		display: none;
		padding-left: 10px;
		padding-right: 0;
		background-color: #fff;
		position: relative;
		overflow: hidden;
		animation: slide-left 0.7s forwards;
		@media screen and (min-width: $break-medium) {
			position: absolute;
			top: 52px;	
			left: -100%;
			z-index: 4000;	
			width: 389px;
			padding-left: 45px;
			padding-right: 0;		
		}
		.toggle {
			position: absolute;
		    left: -21px;
    		top: 6px;
			font-size: 12px;
			width: 20px;
			height: 20px;
			line-height: 20px;
		}
		ul.section-nav {
			height: 100vh;
			padding-bottom: 123px; //header + section nav
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			margin-right: -17px;
			padding-left: 0;    
			margin-top: 21px;
			@media screen and (min-width: $break-medium) {
				padding-bottom: 141px; //header + section nav
			}
			@media screen and (min-width: $break-large) {
				padding-bottom: 153px; //header + section nav
			}
			a {
				letter-spacing:0.59px;
				display: block;
				color: $color-blue-2;
				&:hover {
					text-decoration: underline;
				}
			}
			> li {
				> a {
					@include fontsize(14px);
					text-transform: uppercase;
					font-weight: 700;
   					padding: 5px 0;	
   					@media screen and (min-width: $break-medium) {
						@include fontsize(16px);
					}	
				}
				> ul {
				    padding-left: 15px;
					> li {
						> a {
							@include fontsize(14px);
							text-transform: uppercase;
   							padding: 4px 0;			
		   					@media screen and (min-width: $break-medium) {
								@include fontsize(16px);
							}	
						}
						> ul {
						    padding-left: 27px;
							> li {
								> a {
									@include fontsize(14px);
									font-weight: 500;
									text-transform: initial;
   									padding: 4px 0;	
				   					@media screen and (min-width: $break-medium) {
										@include fontsize(16px);
									}			
								}
							}
						}
					}
				}
			}
			li {
				list-style: none;
				position: relative;
				&.current-page {
					> a {
						color: #000;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.bread-crumbs {	
	padding-left: 30px;    
	z-index: 2;
	@media screen and (max-width: $break-large - 1px) {
		display: none;			
	}
	ul {
		margin: 0;
		padding-left: 0;   
		margin-top: 9px;
		li {
			list-style: none;
			display: inline-block;
		}
	}
	a {
		@include fontsize(12px);	
		font-weight: 500;
		letter-spacing: 0.5px;
		&:hover {
			opacity: 0.65;
		}	
		.icon-arrow-breadcrumb {
			font-size: 8px;
			margin-right: 14px;
			margin-left: 14px;
		}
	}
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?uptjjp');
  src:  url('fonts/icomoon.eot?uptjjp#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?uptjjp') format('truetype'),
    url('fonts/icomoon.woff?uptjjp') format('woff'),
    url('fonts/icomoon.svg?uptjjp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-light-minus:before {
  content: "\e92d";
}
.icon-light-expand:before {
  content: "\e92e";
}
.icon-arrow-pointer-up:before {
  content: "\e901";
}
.icon-start:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-flickr:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-arrow-dropdown:before {
  content: "\e909";
}
.icon-section-collapse:before {
  content: "\e90a";
}
.icon-section-expand:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e90c";
}
.icon-close-square:before {
  content: "\e90d";
}
.icon-quote-outline:before {
  content: "\e90e";
}
.icon-quote:before {
  content: "\e90f";
}
.icon-stem:before {
  content: "\e910";
}
.icon-lifescience:before {
  content: "\e911";
}
.icon-humanities:before {
  content: "\e912";
}
.icon-business:before {
  content: "\e913";
}
.icon-arts:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e916";
}
.icon-alert:before {
  content: "\e917";
}
.icon-menu:before {
  content: "\e918";
}
.icon-arrow-jump-to:before {
  content: "\e919";
}
.icon-expand:before {
  content: "\e91a";
}
.icon-collapse:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-minus:before {
  content: "\e91d";
}
.icon-close:before {
  content: "\e91e";
}
.icon-email:before {
  content: "\e91f";
}
.icon-double-arrows:before {
  content: "\e920";
}
.icon-arrow-round-left:before {
  content: "\e921";
}
.icon-arrow-round-right:before {
  content: "\e922";
}
.icon-arrow-round-up:before {
  content: "\e923";
}
.icon-arrow-round-down:before {
  content: "\e924";
}
.icon-arrow-left:before {
  content: "\e925";
}
.icon-arrow-right:before {
  content: "\e926";
}
.icon-arrow-up:before {
  content: "\e927";
}
.icon-arrow-down:before {
  content: "\e928";
}
.icon-arrow-breadcrumb:before {
  content: "\e929";
}
.icon-arrow-light-left:before {
  content: "\e900";
}
.icon-arrow-light-right:before {
  content: "\e92a";
}
.icon-arrow-light-up:before {
  content: "\e92b";
}
.icon-arrow-light-down:before {
  content: "\e92c";
}
.icon-checkmark:before {
  content: "\e92f";
}

/*-----
	Variables & Mixins
	-----*/

// Colors
$color-black: #222222;
$color-white: #ffffff;
$color-off-white: #f4f4f4;
$color-blue: #003366;
$color-blue-2: #007EA8;
$color-blue-3: #334dff;
$color-blue-4: #00779E;
$color-green: #006633;
$color-purple: #8a1cf6;
$color-red: #e1002a;
$color-yellow: #ffcc33;
$color-yellow-2: #fdffdb;
$color-grey: #e8e8e8;
$color-grey-2: #3a3a3a;
$color-grey-3: #ececec;
$color-grey-4: #c9c9c4;
$color-grey-5: #808080;
$color-grey-6: #d9d9d9;

// Break Points
$break-medium: 700px;
$break-large: 1024px; 
$break-very-large: 1280px; 

// Font sizes in rem with px fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontsize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

// element-invisible improved and more robust
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

//Clears floated containers
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin hideText() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Image replacement
// Kellum Method: http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement

@mixin ir {
  // Kellum Method
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  // Additional helpers
  border: 0;                     // Remove the default border from elements like <button>
  font: 0/0 a;                   // Crush the text down to take up no space
  text-shadow: none;             // Remove any text shadows
  color: transparent;            // Hide any residual text in Safari 4 and any mobile devices that may need it
  background-color: transparent; // Hide the default background color on elements like <button>
}
table.bt tr {
	display: table-row;
	padding-top: 12px;
    padding-bottom: 7px;
    @media screen and (min-width: $break-medium) {
    	padding-top: 13px;
    	padding-bottom: 12px;				
    }
} 
table.bt tbody tr {
	td:first-child() {
		@media screen and (max-width: 827px) {
			padding-top:  25px;							
		}
	}
	td:last-child() {
		@media screen and (max-width: 827px) {
			padding-bottom:  25px;							
		}
	}
}
table.bt thead,
table.bt tbody th {
	display: none;
	min-width: inherit;
}
table.bt thead tr,
table.bt thead tr th {
	display: none;
}

table.bt tfoot th,
table.bt tfoot td,
table.bt tbody td {
	border: none;
	display: block;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	vertical-align: top;
	padding-left: 15px;
	padding-top: 2px;   
	padding-bottom: 6px;
	@include fontsize(14px);		
	@media screen and (min-width: $break-medium) {
	    padding-left: 18px;
		@include fontsize(16px);	
	}
	@media screen and (min-width: $break-large) {
		padding-left: 20px;
	}

	/* IE 9 */
	float: left\9;
	width: 100%\9;

	&::before {
		content: attr(data-th) ": ";
		display: inline-block;
		-webkit-flex-shrink: 0;
		-ms-flex-shrink: 0;
		flex-shrink: 0;
		font-weight: bold;
		color: $color-blue;
		width: 50%;
		@include fontsize(16px);
		padding-right: 10px;
	}

	&.bt-hide {
    	display: none;
    }

    .bt-content {
	    vertical-align: top;    
	    width: 50%;
	    padding-left: 10px;
    }
}
.bt-wrapper.active {
	max-height: 310px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
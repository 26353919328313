.sock-wrapper {
	@include clearfix();
	position: relative;
	background-color: $color-blue;
	text-align: center;
	background-size: cover;    
	background-position: center;
	.overlay {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.3);
	}
	h2 {
		@include fontsize(33px);
		color: #fff; 
		position: relative;	
		padding: 0 20px;	
		margin-bottom: 22px;
		@media screen and (min-width: $break-medium) {		
			@include fontsize(43px);    
			margin-bottom: 25px;		
		}
		@media screen and (min-width: $break-large) {	
			@include fontsize(64px);     
			margin-bottom: 50px;   
		}
	}

	.container {
		position: relative;
		z-index: 1;
		padding-top: 22px;    
		padding-bottom: 30px;
		@media screen and (min-width: $break-medium) {
			padding-top: 37px;
			padding-bottom: 56px;				
		}
		@media screen and (min-width: $break-large) {
		    padding-top: 31px;    
		    padding-bottom: 72px;				
		}
	}
	a.button {
			display: block;
			margin-bottom: 8px;
			max-width: 240px;
			margin: 0 auto 8px;
			width: 100%;
			@media screen and (min-width: $break-medium) {
				display: inline-block;
				margin-left: 6px;
				margin-right: 6px;    
				min-width: 186px;
				max-width: 100%;
				width: auto;
			}
			@media screen and (min-width: $break-large) {
				width: 260px;
				margin-left: 12px;
				margin-right: 12px;
				padding: 15px 10px 11px;
			}
		}
}

footer#colophon {
	background: center top no-repeat url(images/footer-bg.jpg) #0b2239;
	background-size: contain;
	color: #fff;
	position: relative;
	@media screen and (min-width: $break-large) {
		background-size: cover;				
	}
	.container {
		padding-top: 44px;
		padding-bottom: 10px;
		position: relative;
		@media screen and (min-width: $break-medium) {
			padding-top: 36px;
			padding-left: 15px;
			padding-right: 15px;				
		}
		@media screen and (min-width: $break-large) {
			padding-top: 71px;
			padding-left: 33px;
			padding-right: 33px;		
		}
	}
	.footer-logo {
		display: inline-block;
	    margin-bottom: 15px;
		@media screen and (min-width: $break-medium) {
			margin-top: 8px;				
		}
		@media screen and (min-width: $break-large) {
			float: left;				
		}
		img {
			max-width: 136px;
			@media screen and (min-width: $break-large) {
				max-width: 168px;				
			}
		}
	}
	.footer-icons {
		@include clearfix();
		margin-bottom: 32px;
		@media screen and (min-width: $break-medium) {
			float: right;
		    margin-bottom: 48px;				
		}	
		@media screen and (min-width: $break-large) {
			margin-bottom: 35px;			
		}	
		@media screen and (min-width: $break-large + 100px) {
			margin-bottom: 83px;
		}
	}
	#back-to-top {
		float: right;
		margin: 0;
		padding: 0;
		width: 36px;
		height: 36px;
		line-height: 36px;
		vertical-align: middle;
		color: #000;
		background-color: $color-yellow;
		border-radius: 2px;
		font-size: 18px;
		transition: 0.3s;
		@media screen and (min-width: $break-medium) {
			width: 55px;
			height: 55px;
			line-height: 55px;
			margin-left: 20px;    
			font-size: 26px;
		}
		@media screen and (min-width: $break-large) {
		    margin-left: 25px;				
		}
		&:hover {
			background-color: $color-grey-4;
		}
		.icon-arrow-pointer-up {
			@media screen and (min-width: $break-medium) {
			    position: relative;
    			top: 4px;				
			}	
		}
	}
	#social-nav {
		float: left;
		ul {
			padding-left: 0;
			margin: 0;
			li {
				display: inline-block;
				list-style: none;
				margin-right: -1px;
				vertical-align: middle;
				a {
					display: block;
					width: 36px;
					height: 36px;
					line-height: 36px;
					border-radius: 2px;
					vertical-align: middle;
					text-align: center;
					@media screen and (min-width: $break-medium) {
						width: 55px;
						height: 55px;
						line-height: 55px;
					}
					&:hover {
						opacity: 0.75;
					}
					&.facebook {
						background-color: #4460a0;
						span {							
							@media screen and (min-width: $break-medium) {
								font-size: 24px;				
							}
						}
					}
					&.flickr {
						background-color: #ff007f;
						span {
							font-size: 18px;
							@media screen and (min-width: $break-medium) {
								font-size: 28px;				
							}
						}
					}
					&.twitter {
						background-color: #00aaec;
						span {
							font-size: 12px;
							@media screen and (min-width: $break-medium) {
								font-size: 20px;				
							}
						}
					}
					&.youtube {
						background-color: #ce1312;
						span {
							font-size: 12px;
							@media screen and (min-width: $break-medium) {
								font-size: 21px;				
							}
						}
					}
					&.linkedin {
						background-color: #007ebb;
						span {
							font-size: 12px;
							@media screen and (min-width: $break-medium) {
								font-size: 21px;				
							}
						}
					}
					span {
						line-height: 36px;
						@media screen and (min-width: $break-medium) {
							line-height: 55px;
						}
					}
				}
			}
		}
	}
	.phone-email-wrapper {
		@include fontsize(15px);
		margin-bottom: 29px;
		font-weight: 500;	
		@media screen and (min-width: $break-medium) {
			float: left;
			clear: both;
			width: 31.33%;				
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(18px);
			width: auto;
		}
		@media screen and (min-width: $break-large + 100px) {
			clear: none;	
			width: auto;
			float: right;
			margin-right: 7%;	
			margin-top: 16px;				
		}
		a {
			display: block;	
			margin-bottom: 6px;
			vertical-align: middle;
			@media screen and (min-width: $break-large) {		
				margin-right: 46px;	
				float: left;
				&:last-child() {
					margin-right: 0;
				}			
			}
			&:hover {
				color: $color-yellow;
			}
			span {
				margin-right: 7px;
			}
		}	
	}
	.set-height {
		@media screen and (min-width: $break-large) {
			display: flex;
			flex-direction: row;	
			width: 100%;							
		}
	}
	.address {
		@media screen and (min-width: $break-medium) {
			width: 68.66%;
			float: right;				
		}
		@media screen and (min-width: $break-large) {
		    width: 25%;
		    float: left;
		    clear: right;	
		    padding-right: 15px;			
		}
		div.location {	
			margin-bottom: 40px;
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 25px;				
			}
		}
		.location {
			display: block;
			margin-bottom: 25px;
			font-style: normal;
			@media screen and (min-width: $break-medium) {
				width: 50%;
				float: left;				
			}
			@media screen and (min-width: $break-large) {
				width: 100%;
				float: none;
				margin-bottom: 45px;
				&:last-child() {
					margin-bottom: 0;
				}				
			}
			.title {
				@include fontsize(18px);		
				font-weight: 700;
				display: block;
				margin-bottom: 8px;
				@media screen and (min-width: $break-large) {
					@include fontsize(20px);
				}
			}
			.street-address {
				@include fontsize(16px);
				line-height: 1.56;
				display: block;
				@media screen and (min-width: $break-large) {
					@include fontsize(19px);
					line-height: 1.4
				}
			}
		}
	}
	.footer-columns {	
		margin-top: 40px;
		@media screen and (max-width: $break-medium - 1px) {
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 0;							
		}
		@media screen and (min-width: $break-medium) {
			@include clearfix();
			clear: both;
			display: flex;
			flex-direction: row;				
		}
		@media screen and (min-width: $break-large) {
			width: 75%;
			float: left;
			clear: right;
			margin-top: 0;				
		}
		.column {
			@media screen and (min-width: $break-medium) {
				border-right: 1px solid #e8e8e8;
			    padding-top: 21px;
				&:nth-child(1) {
					width: 32%;	
				}	
				&:nth-child(2) {
					width: 36%;   
					padding-left: 23px;	
				}
				&:last-child() {
					width: 32%;   
					border: none;
					padding-left: 23px;
				}		
			}
			@media screen and (min-width: $break-large) {	
				width: 33.33% !important;			
				&:nth-child(1) {
					border-left: 1px solid #e8e8e8;
				}				
			}
			@media screen and (min-width: $break-large) {
				padding-top: 12px;	    
				padding-left: 33px !important;			
			}
			&.expanded {
				button {
					border-bottom-color: transparent;
					span:before {
						@extend .icon-arrow-up:before;
					}
				}
			}
			button {
				@include fontsize(18px);		
				display: block;
				width: 100%;
				height: 52px;
				line-height: 50px;
				vertical-align: middle;
				text-align: left;
				position: relative;
				font-weight: 700;
				letter-spacing:-0.16px;
				transition: 0.3s;
				@media screen and (max-width: $break-medium - 1px) {
					padding-right: 15px;
					padding-left: 15px;	
					margin-bottom: -1px;
					border-top: 1px solid $color-grey;
					border-bottom: 1px solid $color-grey;								
				}
				@media screen and (min-width: $break-medium) {
					pointer-events: none;
					height: auto;	
					line-height: 1;			
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(20px);
				}
				.icon-arrow-down {
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translate(0, -50%);
					font-size: 12px;
					@media screen and (min-width: $break-medium) {
						display: none;				
					}
				}
			}
			ul {
				padding-left: 0;
				margin: 0;
				display: none;
				padding-bottom: 20px;
				@media screen and (max-width: $break-medium - 1px) {
					padding-right: 15px;
					padding-left: 15px;									
				}
				@media screen and (min-width: $break-medium) {
					display: block !important;
					margin-top: 14px;
					padding: 0;				
				}
				li {
					list-style: none;
					display: block;    
					padding: 5px 0;
					@media screen and (min-width: $break-medium) {
				        padding: 3px 0 2px;			
					}
					@media screen and (min-width: $break-large) {
						line-height: 1.2;				
					}
					a {
						font-weight: normal;
						display: inline;
						padding: 5px 0;
						border-bottom: 1px solid transparent;
		    			box-shadow: inset 0 -1px 0 transparent;
						@media screen and (min-width: $break-medium) {
							@include fontsize(16px);
							padding: 3px 0;		
						}
						@media screen and (min-width: $break-large) {
							padding: 4px 0;				
						}
						&:hover {
							color: $color-yellow;
							border-bottom: 1px solid transparent;
		    				box-shadow: inset 0 -1px 0 $color-yellow;
						}
					}		
				}
			}
		}
	}
	.lower-footer {
		@include fontsize(14px);
		margin-top: 39px;
		font-weight: normal;
		@media screen and (min-width: $break-medium) {
		    @include clearfix();
	       	margin-top: 36px;			
		}
		@media screen and (min-width: $break-large) {
			clear: both;			
			@include fontsize(18px);
		    margin-top: 57px;	
		    padding-bottom: 5px;
		}
		span {
			display: block;
			line-height: 1.5;
			@media screen and (min-width: $break-medium) {
				float: left;				
			}
		} 
		p {
			font-weight: 300;
			@media screen and (min-width: $break-medium) {
				float: right;
				margin-top: 0;				
			}
			@media screen and (min-width: $break-large) {
				margin-bottom: 0;				
			}
		}
		a {
			font-weight: 500;
			border-bottom: 1px solid transparent;
			box-shadow: inset 0 -1px 0 transparent;
			&:hover {
				color: $color-yellow;
				border-bottom: 1px solid transparent;
				box-shadow: inset 0 -1px 0 $color-yellow;
			}
		}		
	}
}
/*-----
	Animations
	-----*/
@keyframes fade-in {
	0% { 
		opacity: 0; 
	}   
	100% { 
		opacity: 1;
	}
}
@keyframes fade-in-block {
	0% { 
		opacity: 0;
		transform: translate(0,10%);
	}   
	100% { 
		opacity: 1;
		transform: translate(0,0);
	}
}
@keyframes fade-out {
	0% { 
		opacity: 1; 
	}   
	100% { 
		opacity: 0;
	}
}
@keyframes grow {
	0% { 
		width: 0; 
	}   
	100% { 
		width: 100%;
	}
}
@keyframes indicator {
	0% { 
		opacity: 0; 
	}   
	100% { 
		opacity: 1;
	}
}
@keyframes slide-right {
	0% { 
		left: -100%; 
	}   
	100% { 
		left: 0;
	}
}
@keyframes slide-left {
	0% { 
		left: 0; 
	}   
	100% { 
		left: -100%;
	}
}
@keyframes top-to-bottom {
	0% { 
		top: 5px; 
		opacity: 0;
	}
	66% {
		top: 25px;
		opacity: 1;
	} 
	80% {
		top: 25px;
		opacity: 0;
	}  
	100% { 
		top: 5px;
		opacity: 0;
	}
}
.animate {
	&.welcome-to:before {
		right: -8%;
		opacity: 1;
	}
	&.all-are-welcome:before {
		left: -8%;
		opacity: 1;
	}
	&.generic-block-cta .container:before {
		left: -384px;
		opacity: 1;
	}
}
#our-stories-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow-x: scroll;
	z-index: 6000;
	@media screen and (min-width: $break-medium) {
		padding-top: 90px;
		padding-bottom: 99px;
		padding-left: 15px;
		padding-right: 15px;
	}
	@media screen and (min-width: $break-large) {
		padding-top: 156px;
		padding-bottom: 125px;
		padding-left: 70px;
		padding-right: 70px;
	}
	&:before {
		content: '';
		display: block;
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@media screen and (min-width: $break-medium) {
			background-color: rgba(#011f3e, 0.41);
		}	
	}
	.container {
		position: relative;
		z-index: 1;
		overflow: hidden;    
		padding-left: 6px;
    	padding-right: 6px;
    	@media screen and (min-width: $break-medium) {
    		background-color: #fff;
    		border-radius: 5px;
			padding-left: 23px;
	    	padding-right: 23px;
	    	text-align: center;
    	}
    	@media screen and (min-width: $break-large) {
		    padding-left: 47px;
    		padding-right: 47px;
    	}	
		h2 {
			@include fontsize(28px);
			color: $color-blue;
			padding-left: 10px;    
			margin-top: 18px;
			letter-spacing:1.17px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(44px);
				text-align: center;
				margin-top: 37px;
				margin-bottom: 31px;
			}		
			@media screen and (min-width: $break-large) {
				@include fontsize(64px);   
				margin-top: 43px;
				margin-bottom: 41px;
			}
		}
		> .button {
			margin-top: 30px;
			margin-bottom: 40px;
			display: inline-block;
			min-width: 160px;
			font-weight: 500;
			@media screen and (max-width: $break-medium - 1px) {
				display: none;
			}
			@media screen and (min-width: $break-large) {
			    margin-bottom: 50px;
			}
		}
		#close-stories {
			position: absolute;
			top: 14px;
			right: 14px;
			width: 36px;
			height: 36px;
			line-height: 36px;
			border-radius: 1px;
			border: 1px solid $color-blue;
			@media screen and (min-width: $break-medium) {
			    top: 39px;
    			right: 26px;
			}	
			@media screen and (min-width: $break-large) {
				width: 44px;
				height: 44px;    
				top: 55px;
    			right: 57px;
			}
		}
		.our-stories-list {
			@media screen and (min-width: $break-medium) {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
		}
		.story {
			background-color: $color-blue;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			background-image: none;
			border: 4px solid #fff;
			border-radius: 5px;
			position: relative;
			color: #fff;
			transition: 0.3s;
			@media screen and (min-width: $break-medium) {
				width: calc(50% - 6px);
				margin-bottom: 6px;
				margin-left: 3px;
				margin-right: 3px;
			}
			@media screen and (min-width: $break-large) {
				width: calc(33.33% - 6px);
			}
			&.active {
				border-color: $color-blue-2;
				&:before {
					opacity: 1;
				}
				p {
					opacity: 1;
				}
				.icon-quote-outline:after {
					color: $color-yellow;
				}
				.button {
					background-color: $color-yellow !important;
					border-color: $color-yellow !important;
					color: #000 !important;
				}
			}
			&:hover,
			&:focus {
				@media screen and (min-width: $break-medium) {
					border-color: $color-blue-2;
					&:before {
						opacity: 1;
					}
					p {
						opacity: 1;
					}
					.icon-quote-outline:after {
						color: $color-yellow;
					}
					.button {
						background-color: $color-yellow !important;
						border-color: $color-yellow !important;
						color: #000 !important;
					}					
				}
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(#000, .55);
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: 0.3s;
				@media screen and (max-width: $break-medium - 1px) {
					opacity: 1;			
				}
			}
			&:hover {
				@media screen and (min-width: $break-medium) {
					border-color: $color-blue-2;
				}
			}
			.content {
				position: relative;
				z-index: 1;
				min-height: 188px;
				overflow: hidden;
			    padding-left: 11px;
    			padding-right: 11px;
				text-align: center;    
				padding-bottom: 88px;    
				height: 100%;
				@media screen and (min-width: $break-large) {
					min-height: 300px;
				}
			button {
				position: absolute;
				left: 18px;
				bottom: 23px;
				transition: 0.3s;				
			}
			.icon-quote-outline {
				font-size: 25px;
				color: #fff;
				&:hover:after {
					color: $color-yellow;
				}
				&:after {
					content: "\e90f";
					color: transparent;
					position: absolute;
					left: 0;
					top: 0;
					transition: 0.3s;
				}
			}
			}
			p {
				@include fontsize(16px);
				font-weight: 700;
				letter-spacing: 0;
				line-height: 1.37;
				margin-top: 39px;
				margin-bottom: 0;
				transition: 0.3s;
				opacity: 0;	 
				@media screen and (max-width: $break-medium - 1px) {
					opacity: 1;				
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(22px); 
					margin-top: 55px;
				}	
			}
			.button {
				position: absolute;
				left: 50%;
				bottom: 15px;
				transform: translate(-50%,0);
				min-width: 0;
			    padding: 11px 23px 9px;
			    white-space: nowrap;
				@media screen and (min-width: $break-large) {
				    bottom: 25px;
				}
			}
			button {				
				@media screen and (min-width: $break-large) {
					display: none;
				}
			}
		}
	}
}
body.our-story-active {
	position: relative;
	overflow: hidden;
	#our-stories-modal {
		display: block;
		animation: fade-in 0.3s ease forwards;
	}
}
body.home {	
	button#scroll-indicator {
		display: block;
		margin: 0;
		padding: 0;
		width: 27px;
		height: 43px;
		border: 3px solid $color-yellow;
		position: absolute;
		bottom: 37px;
		left: 50%;
		transform: translate(-50%,0) !important;
		border-radius: 76%/43%;
		opacity: 0;
		animation: fade-in 0.3s 3s ease forwards;
		@media screen and (max-width: $break-large - 1px) {
			display: none;
		}
		.positioner {
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			.indicator {
				display: block;
				width: 7px;
				height: 7px;
				background-color: $color-yellow;
				position: absolute;
				left: 50%;
				top: 5px;
				transform: translate(-50%,0) !important;
				border-radius: 50%;
				animation: top-to-bottom 2s 3s ease infinite;
			}
		}
	}
	header {
		position: relative;
		&.ambient-video {
			video {
				width: 100%;
				height: auto;
			}
		}
		&.static {
			padding-bottom: 66%;
			background-size: cover;
			background-color: $color-blue;
			background-position: center;
			@media screen and (min-width: $break-large) {
				padding-bottom: 57%;
			}
			@media screen and (max-width: $break-medium - 1px) {
				padding-bottom: 0;
				background-image: none !important;
			}
			.mobile {
				padding-bottom: 86%;
				background-size: cover;
				background-position: center;
				@media screen and (min-width: $break-medium) {
					padding-bottom: 0;
					background-image: none !important;
				}
			}
			h1 {
				@include fontsize(42px);
				position: absolute;
				bottom: 0;
				left: 0;
				color: #fff;
				width: 100%;
				text-align: center;
				padding-left: 25px;
				padding-right: 25px;
				line-height: 0.9;
				text-shadow: 0 5px 40px rgba(0,0,0,0.50);
				@media screen and (min-width: $break-medium) {
					@include fontsize(78px);
					padding-left: 100px;
					padding-right: 100px;
					margin-bottom: 48px;
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(96px);
					bottom: auto;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 800px;
					padding: 0;
				}
				a {
					display: inline-block;
					color: #fff;
					box-shadow: none;
					border: none;
					transition: 0.3s;
					&:before {
						display: none;
					}
					&:hover {
						color: $color-yellow;
						text-decoration: none;
						background-color: transparent;
						.icon-arrow-right {
							background-color: $color-yellow;
						}
					}
					.icon-arrow-right {
						color: $color-blue;
						font-size: 12px;
						width: 25px;
						height: 25px;
						line-height: 25px;
						border-radius: 50%;
						background-color: #fff;
						display: inline-block;
						vertical-align: middle;
						text-align: center;
						text-indent: 2px;
						transition: 0.3s;
						@media screen and (min-width: $break-medium) {
							width: 40px;
							height: 40px;
							line-height: 40px;
							font-size: 20px;
						}
						@media screen and (min-width: $break-large) {
							width: 50px;
							height: 50px;
							line-height: 50px;
							font-size: 25px;
						}
					}
				}
			}
		}
	}
}
section.welcome-to {
	@include clearfix();
	position: relative;
	text-align: center;
	overflow: hidden;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0; 
		width: 22%;
		height: 100%;
		background: $color-yellow;
		transform: skewX(-9deg);
		opacity: 0;
		right: -50%;
		transition: 0.3s ease-out;
		transition: 1s cubic-bezier(0.11, 0.95, 0.51, 1.01);
		
		@media screen and (min-width: $break-large) {
			width: 26%;
		}
	}
	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: center top no-repeat url(images/welcome-bg.png);
		background-size: contain;
	}
	.container {
		position: relative;
		z-index: 1;
		padding-top: 29px;
		padding-bottom: 35px;
		@media screen and (min-width: $break-medium) {
			padding-top: 39px;
			padding-bottom: 51px;
		}
		@media screen and (min-width: $break-large) {
		    padding-top: 84px;	    
		    padding-bottom: 112px;			
		}
	}
	h2 {
		@include fontsize(28px);
		color: #000;
		@media screen and (min-width: $break-medium) {
			@include fontsize(48px);   
			margin-bottom: 41px;
		}		
		@media screen and (min-width: $break-large) {
			@include fontsize(72px);   
			margin-bottom: 74px;
		}
		span {
			@include fontsize(18px);
			display: block;
			margin-bottom: 7px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(32px);
			}			
			@media screen and (min-width: $break-large) {
				@include fontsize(40px);
			}	
		}		
	}
	.two-col-grid {
		display: flex;
		flex-direction: column;
		@media screen and (min-width: $break-large) {
			margin-left: -4px;
			margin-right: -4px;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
	.grid {
		margin-bottom: 8px;
		color: #fff;
		position: relative;
		transition: 0.3s ease-out;
		border-radius: 2px;
		@media screen and (min-width: $break-large) {
			width: calc(50% - 8px);
			margin: 0 4px 8px;
		}
		&:nth-child(1) {
			background-color: rgba($color-blue, 0.9);
		}
		&:nth-child(2) {
			background-color: $color-blue-2;
			@media screen and (max-width: $break-large - 1px) {
				order: 4;
			}
		}
		&:nth-child(3) {
			background-color: $color-blue-2;
			@media screen and (max-width: $break-large - 1px) {
				order: 2;
			}
		}
		&:nth-child(4) {
			background-color: $color-blue-2;
			background-color: rgba($color-blue, 0.9);
			@media screen and (max-width: $break-large - 1px) {
				order: 3;
			}
		}
		&:hover,
		&.active {
			transform: translate(0, -10px);
			z-index: 5000;
			.image-overlay {
				opacity: 1;
			}
			.content {
				transform: translate(0, 10px);
			}
		}
		.image-overlay {
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			background-size: cover;
			background-position: center;
			opacity: 0;
			transition: 0.3s ease-out;
			background-color: $color-blue-2;
			background-blend-mode: multiply;
		}
		.content  {
			@include clearfix();
			position: relative;
			z-index: 1;
			transition: 0.3s ease-out;
			padding: 25px 25px 24px;
			@media screen and (min-width: $break-medium) {
			    padding: 25px 30px 28px;
			    min-height: 120px;
			    display: flex;
			    align-items: center;
			}
			@media screen and (min-width: $break-large) {				
				min-height: 300px;
				padding-bottom: 90px;
			    padding-top: 67px;
				display: block;
			}
		}
		h3 {
			@include fontsize(15px);
			letter-spacing: 0.66px;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 30px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(20px);
				letter-spacing: 0.87px;
				float: left;
				max-width: calc(100% - 270px);
				text-align: left;
				margin-bottom: 0;
				line-height: 1.2;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(24px);
				max-width: 100%;
				float: none;
				text-align: center;
				margin-bottom: 65px;
				max-width: 351px;
				margin-left: auto;
				margin-right: auto;
				letter-spacing: 1.05px;
			}
		}
		a.button {
			@include fontsize(12px);
			letter-spacing: 0.53px;
			font-weight: 500;
			display: block;
			max-width: 250px;
			margin: 0 auto;
			@media screen and (min-width: $break-medium) {
				width: 221px;
				letter-spacing: 0;
				position: absolute;
				right: 30px;
				top: 50%;
				transform: translate(0, -50%);
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				width: 321px;
				left: 50%;
				top: auto;
				bottom: 36px;
				transform: translate(-50%, 0);
				margin: 0 auto;
				max-width: 321px;
			}
			&:hover {
				background-color: $color-yellow;
				border-color: $color-yellow;
				color: #000;
				text-decoration: none;
			}
			.desktop {
				display: none;
			}
		}
		.career-select {
			margin-bottom: 0;
			margin-top: 0;
			position: relative;
			max-width: 250px;
			margin: 0 auto;
			@media screen and (min-width: $break-medium) {
				width: 241px;
			    position: absolute;
			    right: 30px;
			    top: 50%;
			    transform: translate(0, -50%);				
			}	
			@media screen and (min-width: $break-large) {
			    width: 321px;
			    left: 50%;
			    top: auto;
			    bottom: 36px;
			    -webkit-transform: translate(-50%, 0);
			    transform: translate(-50%, 0);
			    margin: 0 auto;
			    max-width: 321px;				
			}	
			button.toggle {
				@include fontsize(12px);		
				width: 100%;
				background-color: transparent;
				border-radius: 2px;
				border: 1px solid #fff;
				border-radius: 2px;
				padding: 7px 10px 8px;
				max-width: 100%;
				height: 46px;
				letter-spacing: 0.53px;
				@media screen and (min-width: $break-medium) {
					height: 44px;				
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(16px);
					height: 57px;				
				}
				&.toggled-on .icon-arrow-round-down:before {
					@extend .icon-arrow-round-up:before;
				}
				.icon-arrow-round-down {
					font-size: 12px;
    				vertical-align: middle;
    				margin-left: 7px;
				}
			}
			button.toggle.toggled-on + ul {
				display: block;
			}
			ul {
				position: absolute;
				left: 0;
				top: 100%;
				width: 100%;
				background-color: #fff;
				text-align: left;
				z-index: 2;
				margin: 0 !important;
				padding-left: 0 !important;
				display: none;
				li {
					list-style: none;
					padding-left: 0 !important;
					margin-bottom: 0 !important;
					&:before {
						display: none;
					}
				}
				a {
					color: $color-blue;
					border-bottom: 0;
					box-shadow: none;
					width: 100%;
					display: block;
					padding: 5px 15px;
					&:hover {

					}
				}
			}
		}
	}
}
section.step-inside {
	@include clearfix();
	position: relative;
	@media screen and (min-width: $break-large) {
		background-image: none !important;
	}
	@media screen and (max-width: $break-large - 1px) {
		background-size: cover;
		background-position: center;
		background-color: $color-blue;
	}
	.skrollr-bg {
		background-color: $color-blue;
		background-position: top;
		background-attachment: fixed;    
		background-size: cover;
		width: 100%;
	    height: 100%;
	    z-index: -1;
	    position: absolute;
	    @media screen and (max-width: $break-large - 1px) {
	    	display: none;
	    }
	}
	.overlay {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.3);
	}
	&.animate .container {
		@media screen and (min-width: $break-large) {							
			opacity: 1;
		}
	}
	.container {
		position: relative;
		z-index: 1;
		text-align: center;
		color: #fff;
		padding-top: 40px;
		padding-bottom: 57px;
		@media screen and (min-width: $break-medium) {
			padding-top: 99px;
			padding-bottom: 148px;
		}	
		@media screen and (min-width: $break-large) {
			padding-top: 138px;
			padding-bottom: 178px;
			opacity: 0;
			transition: 0.5s ease-out;
		}
		h2 {
			@include fontsize(30px);
			margin-bottom: 12px;
			color: #fff;
			@media screen and (min-width: $break-medium) {
				@include fontsize(52px);
				margin-bottom: 14px;
			}	
			@media screen and (min-width: $break-large) {
				@include fontsize(64px);
				margin-bottom: 12px;
				letter-spacing: -1.12px;
			}	
		}
		p {
			margin-bottom: 32px;
			max-width: 100% !important;
			@media screen and (min-width: $break-medium) {
				@include fontsize(24px);
			    margin-bottom: 49px;
				margin-top: 8px;
			}
			@media screen and (min-width: $break-large) {	
				@include fontsize(26px);
				margin-bottom: 66px;
			}
		}
		a.button {
			display: block;
			margin-bottom: 8px;
			max-width: 240px;
			margin: 0 auto 8px;
			width: 100%;
			@media screen and (min-width: $break-medium) {
				display: inline-block;
				max-width: 180px;
				margin-left: 8px;
				margin-right: 8px;
			}
			@media screen and (min-width: $break-large) {
				max-width: 100%;
				width: 260px;
				margin-left: 12px;
				margin-right: 12px;
				padding: 15px 10px 11px;
			}
		}
	}
}
section.our-stories-block {
	overflow: hidden;
	@media screen and (min-width: $break-large) {
		margin-bottom: 25px;				
	}
	> .container {
	    padding-top: 7px;
	    @media screen and (min-width: $break-medium) {
    	    padding-top: 2px;				
	    }
	    > p {
	    	margin-top: 11px;
	    	@media screen and (min-width: $break-large) {
	    		line-height: 1.65;				
	    	}
	    }
	}
	h2 {
		margin-bottom: 11px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 23px;				
		}
	}
	.our-stories {
	    margin-top: 23px;
	    @media screen and (min-width: $break-large) {
	    	margin-top: 30px;				
	    }
	    > .container {
	    	padding-top: 248px;
	    	@media screen and (min-width: $break-medium) {
	    		padding-bottom: 169px;	
	    		padding-top: 130px;			
	    	}
	    	@media screen and (min-width: $break-large) {
	    		padding-top: 151px;
	    		padding-bottom: 182px;				
	    	}
	    	p {
	    		@media screen and (min-width: $break-medium) {
	    			max-width: 300px;	    
	    			margin-bottom: 41px;					
	    		}
	    		@media screen and (min-width: $break-large) {
    			    max-width: 367px;
    			    margin-bottom: 83px;				
	    		}
	    	}
	    }
	}
}
.our-stories {
	@include clearfix();
	background-color: $color-blue;
	position: relative;
	background-size: cover;
	background-position: center;
	margin-top: 12px;
	@media screen and (min-width: $break-medium) {
		margin-top: 21px;
	}
	.overlay {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.3);
		@media screen and (min-width: $break-large) {
			opacity: 0.52;
			background-image: linear-gradient(-111deg, rgba(44,44,44,0.90) 0%, rgba(44,44,44,0.10) 56%);
		}	
	}
	.container {
		position: relative;
		z-index: 1;
		text-align: center;
		color: #fff;
		padding-top: 206px;
		padding-bottom: 20px;
		@media screen and (min-width: $break-medium) {
			text-align: left;
			padding-top: 98px;
			padding-bottom: 128px;
		}
		@media screen and (min-width: $break-large) {
			padding-top: 120px;
			padding-bottom: 150px;
		}
		h2 {
			@include fontsize(22px);
			margin-bottom: 6px;
			color: #fff;
			@media screen and (min-width: $break-medium) {
				@include fontsize(32px);
				margin-bottom: 16px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(40px);
				margin-bottom: 23px;
				letter-spacing:-0.7px;
			}		
		}
		p {
			margin-bottom: 22px;
			margin-top: 6px;
			line-height: 1.4;
			@media screen and (min-width: $break-medium) {
				@include fontsize(20px);
				max-width: 380px;
				line-height: 1.55;
				margin-bottom: 70px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);
				line-height: 1.4;
				margin-bottom: 87px;
				letter-spacing:-0.25px;
			}
		}	
		.button {
			display: block;
			margin-bottom: 8px;
			max-width: 240px;
			margin: 0 auto 8px;
			width: 100%;
			@media screen and (min-width: $break-medium) {				
				max-width: 100%;
				width: auto;
				margin-left: 0;
				padding: 7px 10px 5px;
			}
			@media screen and (min-width: $break-large) {
				padding: 14px 18px 10px;
			}
			.icon-light-expand {
				margin-right: 13px;
			}
		}
	}
}
section.all-are-welcome {
	position: relative;
	overflow: hidden;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		width: 22%;
		height: 100%;
		background: $color-blue-2;
		transform: skewX(9deg);
		opacity: 0;
		left: -50%;
		transition: 0.3s ease-out;
		transition: 1s cubic-bezier(0.11, 0.95, 0.51, 1.01);
		@media screen and (max-width: $break-medium - 1px) {
			display: none;
		}
		@media screen and (min-width: $break-large) {
			width: 26%;
		}
	}
	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: center top no-repeat url(images/campus-center.png);
		background-size: cover;
		@media screen and (max-width: $break-medium - 1px) {
			background-image: none;
		}
	}
	.container {
		position: relative;
		z-index: 1;
		text-align: center;
		padding-top: 43px;
		padding-bottom: 44px;
		@media screen and (max-width: $break-medium - 1px) {
			padding-left: 0;
			padding-right: 0; 			
		}
		@media screen and (min-width: $break-medium) {
			padding-top: 22px;
			padding-bottom: 84px;
		}
		@media screen and (min-width: $break-large) {
			padding-top: 87px;
			padding-bottom: 90px;
		}
	}
	h2 {
		@include fontsize(30px);
		margin-bottom: 12px;
		font-weight: 900;
		letter-spacing: -0.53px;
		padding: 0 15px;
		color: #000;
		@media screen and (min-width: $break-medium) {
			@include fontsize(52px);
			margin-bottom: 15px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(64px);
			margin-bottom: 34px;
		}
	}
	.stat-container {
		position: relative;
		margin-bottom: 32px;
		@media screen and (min-width: $break-medium) {
			margin-bottom: 38px;
		}
		.slick-track {
			margin-top: 1px;
			@media screen and (max-width: $break-medium - 1px) {
				display: flex;
			}
		}
	}
	.stat {
		border-top: 1px solid $color-grey;
		border-bottom: 1px solid $color-grey;
		margin-top: -1px;
		padding: 30px 50px 5px;
		@media screen and (max-width: $break-medium - 1px) {
			display: flex;
			flex-direction: column;
			height: auto;
			align-items: center;
			justify-content: center;
		}
		@media screen and (min-width: $break-medium) {
			@include clearfix();
			padding: 18px 50px 3px;
		}
		@media screen and (min-width: $break-large) {
			padding: 32px 50px 6px;
		}
	}
	.number {
		@include fontsize(65px);
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: 700;
		color: $color-red;
		line-height: 1;
		@media screen and (min-width: $break-medium) {
			float: left;
			width: 41%;
			text-align: right;
			padding-right: 10px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(75px);
			width: 43%;
		}		
	}
	p {
		@include fontsize(20px);
		font-weight: 500;
		margin-top: 6px;
		width: 100%;
		@media screen and (min-width: $break-medium) {
			@include fontsize(22px);
			float: left;
			width: 59%;
			text-align: left;
			padding-left: 10px;
			margin-top: 0;
			margin-bottom: 17px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(26px);
			width: 57%;
			max-width: 331px;
			padding-left: 16px;
			line-height: 1.4;
		}		
	}
	button.slick-prev {
		position: absolute;
		left: 4px;
		top: 50%;
		transform: translate(0, -50%);
		font-size: 23px;
		z-index: 2;
	}
	button.slick-next {
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translate(0, -50%);
		font-size: 23px;
		z-index: 2;
	}
}
section.jump-in {
	background-size: cover;
	background-position: center;
	overflow: hidden;
	@media screen and (min-width: $break-medium) {
		padding-bottom: 53px;
	}
	.blocks-wrapper {  
		@media screen and (min-width: $break-large) {
			padding-bottom: 80px !important;				
		}
	}
	&.active {
		.blocks-wrapper {    
			margin-bottom: 60px;
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 17px;				
			}
			@media screen and (min-width: $break-large) {
				padding-bottom: 80px;				
			}
			.block-4 {
				display: block;				
		    	animation: fade-in-block 0.3s ease-out forwards;
			}
			.block-5 {
				display: block;				
		    	animation: fade-in-block 0.3s 0.1s ease-out forwards;
			}
			.block-6 {
				display: block;				
		    	animation: fade-in-block 0.3s 0.2s ease-out forwards;
			}
		}
	}
	.container {
		h2 {
			@include fontsize(30px);
			margin-bottom: 12px;
			font-weight: 900;
			letter-spacing: -0.53px;
			padding: 0 15px;
			text-align: center;
			margin-top: 65px;
			margin-bottom: 27px;
			color: #000;
			@media screen and (min-width: $break-medium) {
				@include fontsize(52px);
				margin-bottom: 43px;
				margin-top: 110px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(64px);
				margin-bottom: 45px;
				margin-top: 120px;
			}
		}
	}
	.blocks-wrapper {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: 0.3s;
		@media screen and (min-width: $break-medium) {
			@include clearfix();
			margin-bottom: 0;
			flex-direction: row;
			flex-wrap: wrap;
			padding-bottom: 30px; //the offset size of the staggered elements
		}
		@media screen and (min-width: $break-large) {
			padding-bottom: 0;
		}
		> div {
			&.block {
				@media screen and (min-width: $break-medium) {
					flex: .5 .33 49.8%;
				}
				@media screen and (min-width: $break-large) {
					flex: .3 .25 33%;
				}
			}
			&:nth-child(1) {
				@media screen and (min-width: $break-large) {
					position: relative;
					top: 50px;
				}
			}
			&:nth-child(2) {	
				@media screen and (min-width: $break-medium) {
					position: relative;
					top: 30px;
				}
				@media screen and (min-width: $break-large) {
					position: relative;
					top: 80px;
				}
			}
			&:nth-child(3) {
			}
			&:nth-child(4) {
				position: relative;				
				display: none;
				opacity: 0;
				@media screen and (min-width: $break-medium) {
					top: 30px;
				}
				@media screen and (min-width: $break-large) {
					position: relative;
					top: 50px;
					display: block;
					opacity: 1;
				}

			}
			&:nth-child(5) {
				position: relative;	
				display: none;
				opacity: 0;
				@media screen and (min-width: $break-large) {
					position: relative;
					top: 80px;
					display: block;
					opacity: 1;
				}

			}
			&:nth-child(6) {
				position: relative;	
				display: none;
				opacity: 0;
				@media screen and (min-width: $break-medium) {
					position: relative;
					top: 30px;
				}
				@media screen and (min-width: $break-large) {
					top: 0;
					display: block;
					opacity: 1;
				}

			}
		}	
	}
	.block {
		min-height: 320px;
		background-repeat: no-repeat;
		background-size: cover;
		@media screen and (min-width: $break-medium) {
			width: 50%;
			float: left;
			min-height: 350px;
		}
		@media screen and (min-width: $break-large) {
			width: 33.33% !important;
			float: left !important;
			min-height: 426px;
			&:before {
				content: '';
				float: left;
				padding-bottom: 100%;
			}
			&:after {
				content: '';
				display: table;
				clear: both;
			}			
		}
		.container {
			color: #fff;
			position: relative;
			z-index: 1;
			padding-top: 12px;
			padding-bottom: 20px;
			@media screen and (min-width: $break-medium) {
				padding-left: 23px;
				padding-right: 23px;
			}	
			@media screen and (min-width: $break-large) {
			    padding-left: 42px;
    			padding-right: 35px;   
    			padding-top: 9px;   
    			padding-bottom: 36px;
			}	
			h3 {
				text-transform: uppercase;
				margin-right: 95px;
				color: #fff;
				@media screen and (min-width: $break-medium) {
					@include fontsize(24px);
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(32px);
				}
			}			
			a {			
				border: none !important;
				box-shadow: none !important;
				&:hover {
					background-color: transparent;
				}
			}
			.see-more {
				@include fontsize(16px);
				position: absolute;
				right: 10px;
				top: 36px;
				font-weight: 500;
				letter-spacing:0.25px;
				color: #fff;
				transition: 0.3s;
				@media screen and (min-width: $break-medium) {
					right: 17px;
					top: 34px;
				}	
				@media screen and (min-width: $break-large) {
    				top: 47px;
				}
				&:hover {
					color: $color-yellow;
				}		
				span.icon-arrow-light-right {
					font-size: 12px;
					margin-right: 2px;
				}
			}
		}
		.button {
			position: absolute;
			left: 50%;
			bottom: 30px;
			transform: translate(-50%,0);
			width: 240px;
			text-align: center;
			@media screen and (min-width: $break-medium) {
				width: auto;
	    		padding: 9px 16px 6px;
	    		white-space: nowrap;
				bottom: 40px;				
			}
			@media screen and (min-width: $break-large) {
				padding: 14px 36px 12px;
				bottom: 60px;				
			}
		}
	}
	.events {
		background-color: $color-blue-2;
		@media screen and (min-width: $break-medium) {
			width: 50%;
			float: right;
			//margin-top: 30px;
		}
		@media screen and (min-width: $break-large) {
			//margin-top: 80px;
		}
		.container {
			@media screen and (min-width: $break-medium) {
				padding-left: 33px;
				padding-right: 33px;
				padding-bottom: 0;
			}
			@media screen and (min-width: $break-large) {
				padding-left: 40px;
			}
		}
		.event {
			display: flex;
			flex-direction: row;
			margin-bottom: 25px;
			@media screen and (min-width: $break-large) {
				margin-bottom: 33px;
			}
			.date {	
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 46px;
				border-right: 1px solid #fff;
				margin-right: -1px; 
				@media screen and (min-width: $break-medium) {
					width: 59px;
				}
				.month {
					@include fontsize(12px);
					text-transform: uppercase;
					display: block;
					line-height: 1;
					font-weight: 700;
					letter-spacing:2.57px;
					@media screen and (min-width: $break-large) {
						@include fontsize(14px);
						font-weight: 500;    
						margin-bottom: 4px;
					}			
				}
				.day {
					@include fontsize(26px);
					font-weight: 900;
					letter-spacing: 0.46px;
					@media screen and (min-width: $break-large) {
						@include fontsize(34px);
						letter-spacing: 0.61px;
					}	
				}
			}
			.meta {
				@include fontsize(14px);
				border-left: 1px solid #fff;
				color: #fff;
				padding-left: 11px;
				letter-spacing:0.25px;
				line-height: 1.5;
				@media screen and (min-width: $break-medium) {
				    padding-left: 20px;
				}
				.event-link {
					font-weight: 900;
					color: #fff;
					transition: 0.3s;
					@media screen and (min-width: $break-large) {
						@include fontsize(16px);
					}
					&:hover {
						color: $color-yellow;
					}
					.icon-arrow-light-right {
						font-size: 9px;
					}	

				}
				.time {
					color: $color-yellow-2;
					display: block;
				}
				.location {
					display: block;
				}
			}
		}
	}
	.instagram {
		position: relative;
		a.image {
			display: block;
		    width: 100%;
		    height: 100%;
		    box-shadow: none;
		    border: none;    
		    background-size: cover;
		    background-position: center;
		    position: absolute;
		    &:hover {
		    	background-color: transparent;
		    }
		}
		.instagram-link {
			position: absolute;
			left: 16px;
			top: 16px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: $color-red;
			color: #fff;
			display: block;
			z-index: 2;
			line-height: 50%;
			text-align: center;
			border: none !important;
			box-shadow: none !important;
			@media screen and (min-width: $break-medium) {
				left: 21px;
	    		top: 21px;
			}
			@media screen and (min-width: $break-large) {
				left: 29px;
    			top: 29px;
			}
			&:before {
				display: none;
			}
			&:hover {
				text-decoration: none;
			}
			span.icon-instagram {
				vertical-align: middle;
				line-height: 50px;
				font-size: 28px;
			}
		}
		a.instagram_image {
			padding-bottom: 100%;
			min-height: 320px;
			display: block;
			background-size: cover;
			background-position: center;
		}
	}

	/*removed on request*/
	.button-block {
		margin-top: 35px;
		order: 7;
		@media screen and (min-width: $break-medium) {
			position: relative;
			padding-bottom: 50%;
			background-color: rgba(#000, 0.2);
			float: right;
			width: 50%;
			margin-top: 0;
			top: 30px;
		}
		@media screen and (min-width: $break-large) {
			float: none;
			width: 100%;
			overflow: hidden;
			clear: both;
			padding-bottom: 0;
			background-color: transparent;
			top: auto;
		    margin-top: 80px;
		    display: none !important;
		}
	}
	#jump-in-addition {
		clear: both;    
    	overflow: hidden;
		.block {
			background-size: cover;
			position: relative;
			background-position: center;
			margin-bottom: 0 !important;
			&:nth-child(1) {
				@media screen and (min-width: $break-large) {
				    margin-top: 50px;
				}
			}
			&:nth-child(2) {
				@media screen and (min-width: $break-large) {
				    margin-top: 80px;
				}
			}
		}
	}
	button#load-jump-in {
		text-align: center;
		margin: 0 auto;
		display: block;
		margin-bottom: 43px;  
	   	vertical-align: middle;
	   	padding: 11px 12px 9px;
	   	letter-spacing: 0.5px;
		@media screen and (min-width: $break-medium) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			white-space: nowrap;
			padding: 7px 12px 7px;
		}
		@media screen and (min-width: $break-large) {
			position: static;
			transform: none;
			margin-top: 40px;    
			margin-bottom: 6px;
			padding: 14px 16px 12px;
		}
		.icon-light-expand {
			margin-right: 14px;
		    font-size: 16px;
		    @media screen and (min-width: $break-medium) {
	    	    position: relative;
    			top: 2px;				
		    }	
		}
	}
}
section.generic-cta {
	text-align: center;
	.container {		
		padding-bottom: 35px;
		@media screen and (min-width: $break-medium) {
			padding-top: 59px;
			padding-bottom: 99px;
		}
		@media screen and (min-width: $break-large) {
			padding-top: 120px;
   			padding-bottom: 161px;				
		}	
	}
	h2	{
		@include fontsize(32px);
		margin-top: 39px;
		margin-bottom: 19px;
		color: #000;
		@media screen and (min-width: $break-medium) {			
			@include fontsize(58px);
			margin-bottom: 30px;
		}
		@media screen and (min-width: $break-large) {			
			@include fontsize(72px);
		}
	}
	.button {
		display: inline-block;
	   	letter-spacing: 0.5px;
	   	padding: 11px 16px 9px;
	   	@media screen and (min-width: $break-medium) {
   		    padding: 9px 16px 6px;
	   	}
		.icon-start {
		    font-size: 20px;
		    margin-right: 8px;
		    vertical-align: middle;
		}
		.mobile {
			@media screen and (min-width: $break-medium) {
				display: none;
			}
		}
		.desktop {
			display: none;
			@media screen and (min-width: $break-medium) {
				display: inline-block;
			}
		}
	}
}
header.hero-image {
	background-color: $color-blue !important;
	background-position: center;
	@media screen and (max-width: $break-medium - 1px) {
		background-image: none !important;	
	}
	.mobile {
		background-size: cover;
		background-position: center;
		overflow: hidden;
		@media screen and (min-width: $break-medium) {
			background-image: none !important;				
		}
	}

}
header.hero-image,
header.no-hero-image {
	overflow: hidden;
	position: relative;
	color: #fff;
	background-color: $color-off-white;
	background-size: cover;
	background-position: center center;
	margin-bottom: 50px;
	@media screen and (min-width: $break-large) {
	    margin-bottom: 84px;				
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity:0.4;
		background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 98%);
	}
	.container {
		position: relative;
		z-index: 1;
	    padding-top: 172px;
	    @media screen and (min-width: $break-medium) {
    	    padding-top: 197px;
    	    padding-left: 30px;
    	    padding-right: 30px;
    	    padding-bottom: 17px;
	    }
	    @media screen and (min-width: $break-large) {
	    	padding-top: 250px;
    	    padding-left: 50px;
    	    padding-right: 50px;
	    }
	    @media screen and (min-width: $break-large) {
	    	padding-bottom: 25px;
	    }
	    h1 {    
	    	margin-bottom: 10px;
	    	@media screen and (min-width: $break-medium) {
    		    margin-bottom: 23px;
	    	}
	    	@media screen and (min-width: $break-large) {
    		    margin-bottom: 45px;
	    	}
	    }
	    .sub-title {
	    	@include fontsize(16px);
	    	border: 1px solid $color-yellow;
	    	color: #fff;
	    	font-weight: normal;
	    	position: relative;	
    	    padding: 12px 10px 14px 42px;
    	    margin-top: 11px;
    		margin-bottom: 21px;    
    		line-height: 1.4;
    		letter-spacing:-0.12px;
    		display: inline-block;
    		@media screen and (min-width: $break-medium) {
    			@include fontsize(18px);
    			letter-spacing: -0.14px;		
			    padding-left: 53px;
			    padding-right: 25px;
			    margin-top: -10px;
			    margin-bottom: 9px;
			    line-height: 1.4;				
    		}
    		@media screen and (min-width: $break-large) {
				@include fontsize(26px);
				letter-spacing:-0.2px;
				margin-top: -32px;	
				padding: 11px 27px 11px 56px;	
			}	
	    	.icon-start {
	    		color: $color-yellow;
	    		position: absolute;
	    		left: 10px;
	    		top: 50%;
	    		transform: translate(0, -50%);
	    		@media screen and (min-width: $break-medium) {
	    			left: 21px;
	    			font-size: 20px;				
	    		}
	    		@media screen and (min-width: $break-large) {
	    			left: 22px;				
	    		}
	    	}
	    }
	    p {
	    	max-width: 631px;
	    	margin-top: 10px;
	    	@media screen and (min-width: $break-medium) {
	    		@include fontsize(20px);
	    	}
	    	@media screen and (min-width: $break-large) {
	    		@include fontsize(26px);
	    		max-width: 910px;
	    	}
	    }
	}
}
header.no-hero-image {
	color: $color-blue;
	&:before {
		display: none;
	}
	.container {
	    padding-top: 136px;
	    @media screen and (min-width: $break-medium) {
	    	padding-top: 148px;				
	    }
	    @media screen and (min-width: $break-large) {
	    	padding-top: 186px;				
	    }
	}
	p {
		color: $color-grey-2;
	}
}
section.three-up {
	.container {
		padding-top: 5px;
		text-align: center;
		h2 {
	        margin-top: 8px;
		    text-align: left;
		    margin-bottom: 15px;
		}
		img {
			margin-bottom: 8px;
			max-width: 100%;
			@media screen and (min-width: $break-medium) {
				max-width: 100%;
				width: 100%;    
				margin-bottom: 15px;
			}
		}
		p {
			margin-top: 5px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);    
				line-height: 1.35;
			}
		}
		.row {
			@include clearfix();
			@media screen and (min-width: $break-medium) {
				margin-left: -6px;
				margin-right: -6px;
		    	margin-top: 21px;
			}
			@media screen and (min-width: $break-large) {
				margin-left: -15px;
				margin-right: -15px;    
				margin-top: 33px;
			}
		}
		.column {
			margin-bottom: 20px;
			text-align: left;
			@media screen and (min-width: $break-medium) {
				width: calc(33.33% - 12px);
				float: left;
				margin-left: 6px;
				margin-right: 6px;    
				margin-bottom: 9px;
			}
			@media screen and (min-width: $break-large) {
				width: calc(33.33% - 30px);
				margin-left: 15px;
				margin-right: 15px;
			}
			h3 {
				margin: 0;
			}
			a {
				@media screen and (min-width: $break-large) {
					@include fontsize(22px);
				}
			}
		}
		.button {
			display: inline-block;
			text-align: center;
			margin: 9px auto 0;
			@media screen and (min-width: $break-medium) {
			    min-width: 0;				
			}
			@media screen and (min-width: $break-large) {
			    margin: 28px auto 0;				
			}
		}
	}
}
section.degree-cert-program {
	text-align: center;
	.container {
		padding-top: 16px;
		@media screen and (min-width: $break-medium) {
		    padding-top: 7px;				
		}
		.row {
			@include clearfix();
			text-align: left;
			@media screen and (min-width: $break-large) {
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		.column {
			margin-bottom: 20px;
			@media screen and (min-width: $break-large) {
				width: calc(33.33% - 30px);
				float: left;
				margin-left: 15px;
				margin-right: 15px;
			    margin-bottom: 25px;
			}
		}
		h2 {
		    margin-top: 11px;
		    text-align: left;
	        margin-bottom: 18px;
	        @media screen and (min-width: $break-medium) {
        	    margin-bottom: 22px;
        	    margin-top: 30px;				
	        }
	        @media screen and (min-width: $break-large) {
	        	margin-bottom: 30px;				
	        }
		}
		span {
   			@include fontsize(16px);
   			color: $color-grey-2;
			display: block;    
			line-height: 1.3;
   			margin-top: 5px;
   			font-weight: 700;
   			@media screen and (min-width: $break-large) {
				@include fontsize(18px);
				margin-bottom: 12px;
				margin-top: 5px;
			}		
			@media screen and (min-width: $break-large) {
				margin-top: 2px;				
			}
		}
		p {
		    margin-top: 6px;
  			line-height: 1.2;
  			@media screen and (min-width: $break-medium) {
  				@include fontsize(14px);    
  				line-height: 1.4;
  			}
  			@media screen and (min-width: $break-large) {
  				@include fontsize(16px);
  				margin-top: 16px;
			    line-height: 1.65;
  			}
		}
		h3 {
			margin: 0;
			a:not(.button) {
				@include fontsize(22px);
				@media screen and (min-width: $break-large) {
					@include fontsize(28px);
				}	
			}
		}
		.button {
			margin: 11px auto 0;
			text-align: center;
			@media screen and (min-width: $break-medium) {
				margin: 7px auto 0;				
			}
		}
	}
}
section.office {
	.container {
		padding-top: 5px;
		padding-bottom: 35px;
		@media screen and (min-width: $break-large) {
			padding-bottom: 27px;				
		}
		> p {
			margin-bottom: 30px !important;
			@media screen and (max-width: $break-large - 1px) {
				display: none;
			}
			@media screen and (min-width: $break-large) {
			    line-height: 1.65;				
			}
		}
		h2 {
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 22px;				
			}
			@media screen and (min-width: $break-large) {
				margin-bottom: 20px;
			}
		}
		.row {
			@media screen and (min-width: $break-large) {
				margin-left: -15px;
				margin-right: -15px;
				display: flex;
				flex-direction: row;
			}
		}
		.column {
			background-color: $color-off-white;
			padding: 24px 16px 23px;
			margin-bottom: 16px;
			@media screen and (min-width: $break-medium) {
			    padding: 24px 26px 29px;				
			}
			@media screen and (min-width: $break-large) {
				width: calc(33.33% - 15px);
				margin-left: 15px;
				margin-right: 15px;
				padding: 28px 31px 48px;
			}
		}
		h3 {
			@include fontsize(22px);
			margin-bottom: 13px;
			display: inline-block;
			letter-spacing: -0.4px;
			line-height: 1.4;
			transition: 0.3s;
			margin-top: 0;
			display: block;
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 9px;				
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);    
				line-height: 1.2;
				margin-bottom: 24px;
			}	
			&:hover {
				color: $color-blue;
			}
		}
		a {
			border: none !important;
			box-shadow: none !important;
			color: $color-blue-4;
			transition: 0.3s;
			&:hover {
				background-color: transparent;
				color: $color-blue;
			}
			&:after {
				display: none;
			}
			&[href^="mailto:"] {
				@include fontsize(14px);
				display: block;
				color: $color-grey-2;
				font-weight: 700;
				margin-bottom: 4px;
				@media screen and (min-width: $break-medium) {
					margin-bottom: 0;				
				}
				@media screen and (min-width: $break-large) {					
					@include fontsize(18px);
				}
			}
			.icon-arrow-right {
				font-size: 15px;
				margin-left: 2px;
			}
		}
		p {
			@include fontsize(16px);
			margin: 0;
			line-height: 1.5;
			@media screen and (min-width: $break-large) {					
				@include fontsize(18px);
			}	
		}
	}
}
section.photo-grid {
	background-color: $color-off-white;
	margin-top: 37px;
	padding-bottom: 29px;
	margin-bottom: 15px;
	@media screen and (min-width: $break-medium) {
	    margin-top: 10px;
	    padding-top: 12px;
        padding-bottom: 55px;				
	}
	@media screen and (min-width: $break-large) {
		padding-top: 20px;	
		padding-bottom: 0;			
	}
	&.animate {				
		@for $i from 0 through 7 {
		   a:nth-child(#{$i}) {
		    	animation: fade-in 0.3s ($i * 0.1s) ease-out forwards;
		    }
		}
	}
	.container {
		padding-top: 22px;
		@media screen and (min-width: $break-large) {
			padding-bottom: 73px;				
		}
	}
	h2 {
	    margin-bottom: 11px;
	    @media screen and (min-width: $break-medium) {
    	    margin-bottom: 9px;				
	    }
	    @media screen and (min-width: $break-large) {
	    	margin-bottom: 23px;				
	    }
	}
	p {
		margin-top: 5px;
		@media screen and (min-width: $break-medium) {
			line-height: 1.4;
			margin-bottom: 8px;				
		}
		@media screen and (min-width: $break-large) {
			max-width: 900px;				
		}
	}
	.row {
		position: relative;
		margin-left: -15px;
		margin-right: -15px;
		margin-top: 22px;
		display: flex;
		flex-direction: column;
		background-color: $color-blue;
		background-size: cover;
		@media screen and (min-width: $break-medium) {
			flex-direction: row;
			flex-wrap: wrap;
			margin-left: -6px;
			margin-right: -6px;
			margin-top: 14px;
		}
		@media screen and (min-width: $break-large) {
			margin-top: 34px;				
		}
		&:before {
			position: absolute;
			content: '';
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-blue, 0.70);
		}
		a {
			@include fontsize(22px);
			min-height: 92px;
			align-items: center;
			font-weight: 700;
			display: block;
			width: 100%;
			height: 100%;
			padding: 15px;
			color: #fff;
			border-bottom: 1px solid #fff;
			opacity: 0;
			display: flex;
			position: relative;
			box-shadow: none;
			@media screen and (min-width: $break-medium) {
				width: calc(50% - 12px);
				margin: 6px;
				min-height: 296px;
				border-bottom: 0;
				text-align: center;
				justify-content: center;    
				padding: 15px 30px;
				line-height: 1.5;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(26px);
				width: calc(33.33% - 12px);
				min-height: 336px;	  
				padding: 15px 45px; 
			}
			&:last-child {
				@media screen and (max-width: $break-medium - 1px) {
					border-bottom: none;
				}
			}
			&:before {
				opacity: 0;
				position: absolute;
				content: '';
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $color-blue;
				transition: 0.3s;
				z-index: 1;
				transform: none !important;
				@media screen and (min-width: $break-medium) {
					opacity: 1;
					background-color: transparent;
					border: 6px solid $color-off-white;
					width: 100%;
				    height: calc(100% + 1px);
				    top: -7px;
			        left: -6px;
    				width: calc(100% + 1px);
				}
			}
			&:hover {
				color: $color-yellow;
				background-color: transparent;
				&:before {
					opacity: 1;
					@media screen and (min-width: $break-medium) {
						background-color: $color-blue;
					}
				}
			}
			&:not([href]) {
				pointer-events: none;
				@media screen and (max-width: $break-medium - 1px) {
					display: none;
				}
				&:hover:before {
					background-color: transparent;
				}
			}
			span {
				position: relative;
				z-index: 1;
			}
			.icon-arrow-right {
				font-size: 14px;
				margin-left: 3px;
				@media screen and (min-width: $break-large) {
				    font-size: 17px;
				}
			}
		}
	}
}
section.three-column-cta {
	padding-top: 25px;
	@media screen and (min-width: $break-large) {
	    padding-top: 2px;				
	}
	h2 {
		margin-bottom: 11px;
		@media screen and (min-width: $break-large) {
		    margin-bottom: 22px;				
		}
	}
	p {
		margin-top: 11px;
		@media screen and (min-width: $break-large) {
			line-height: 1.6;				
		}
	}
	.row {    
		margin-top: 23px;
		@media screen and (min-width: $break-medium) {
		    margin-top: 26px;				
		}
		@media screen and (min-width: $break-large) {
			margin-left: -15px;
			margin-right: -15px;
			display: flex;
			flex-direction: row;
			margin-top: 33px;
		}
	}
	.column {
		background-color: $color-off-white;
	    padding: 6px 16px 27px;
		margin-bottom: 16px;
		@media screen and (min-width: $break-medium) {
			padding: 14px 30px 39px;
			margin-bottom: 12px;
			position: relative;
		}
		@media screen and (min-width: $break-large) {
			width: calc(33.33% - 30px);
			margin: 0 15px;
		    padding-bottom: 144px;
			padding-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		h3 {
			@include fontsize(22px);
			color: $color-grey-2;
			margin-bottom: 14px;
			@media screen and (min-width: $break-medium) {
				margin-right: 225px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);
				margin-right: 0;
			    margin-bottom: 26px;
			}
		}
		a {
			&:not(.button) {
				border: none !important;
				box-shadow: none !important;
				&:hover {
					background-color: transparent;
				}				
			}
			&:after {
				display: none;
			}
			&[href^="mailto:"] {
				@include fontsize(14px);
				display: block;
				color: $color-grey-2;
				font-weight: 700;
				margin-bottom: 4px;
				@media screen and (min-width: $break-medium) {
					margin-bottom: 0;				
				}
				@media screen and (min-width: $break-large) {					
					@include fontsize(18px);
				}
			}
			.icon-arrow-right {
				font-size: 15px;
				vertical-align: middle;
			}
		}
		p {
			margin-top: 10px;
			margin-bottom: 28px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(16px);
				margin-right: 254px;
				margin-bottom: 0;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(18px);
				margin-right: 0;
			}
		}
		.button {
			display: block;
			max-width: 251px;
			text-align: center;
			margin-top: 25px;
			margin-left: 0;
			@media screen and (min-width: $break-medium) {
				position: absolute;
				width: 181px;
				right: 30px;
				top: 50%;
				transform: translate(0,-50%);
				margin-top: 0;
			}
			@media screen and (min-width: $break-large) {
				width: 264px;
				max-width: 100%;
				top: auto;
				left: 50%;
			    bottom: 51px;
				margin: 0 auto;
				transform: translate(-50%,0);
			}
		}
	}
}
section.three-column {
	.container { 
		padding-top: 3px;
		@media screen and (min-width: $break-medium) {
			padding-top: 0px;				
		}
		&:after {
			margin-top: 20px;
		}
		h2 {
			margin-bottom: 11px;
			@media screen and (min-width: $break-large) {
			    margin-bottom: 23px;				
			}
			+ div.row {
				@media screen and (min-width: $break-large) {
					margin-top: 0;				
				}
			}
		}
		p {
			margin-top: 11px;
			@media screen and (min-width: $break-large) {
				line-height: 1.65;				
			}
		}
		.row {
			margin-top: 26px;
			@media screen and (min-width: $break-medium) {
			    margin-top: 22px;				
			}
			@media screen and (min-width: $break-large) {
				@include clearfix();
				margin-left: -15px;
				margin-right: -15px;   
				margin-top: 32px;    
				margin-bottom: 36px;
			}
		}
		.column {
			margin-bottom: 22px;
			@media screen and (min-width: $break-medium) {
				@include clearfix();  
				@include fontsize(16px);  
				margin-bottom: 11px;
			}
			@media screen and (min-width: $break-large) {				
				width: calc(33.33% - 30px);
				float: left;
				margin-left: 15px;
				margin-right: 15px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			p {
				@include fontsize(14px);    
				line-height: 1.4;
				margin-bottom: 5px;
				@media screen and (min-width: $break-medium) {
					margin-bottom: 10px;				
				}
				@media screen and (min-width: $break-large) {
					line-height: 1.625;
					@include fontsize(16px);  				
				}
			}
			ul {
				margin-top: 7px;
				margin-bottom: 0;
			}
		}
		a:before {
			display: none;
		}
		img {
			@media screen and (min-width: $break-medium) {
				float: left;
				width: 192px;
				margin-right: 25px;
			}
			@media screen and (min-width: $break-large) {
				float: none;
				width: auto;
				margin-right: 0;
			}
			img {
				width: 100%;
				border-radius: 2px;
				@media screen and (min-width: $break-large) {
					margin-bottom: 18px;				
				}
			}
		}
		.image + .content {
			@media screen and (min-width: $break-medium) {
			    padding-left: 217px;
			}
			@media screen and (min-width: $break-large) {
				padding-left: 0;
			}
		}
		h3 {
			@include fontsize(18px);
			margin-top: 11px;    
			margin-bottom: 12px;
			@media screen and (min-width: $break-large) {
				@include fontsize(22px);
			}		
		}
		h4 {
			@include fontsize(14px);
			text-transform: uppercase;
			color: $color-grey-2;
			margin-bottom: 0;
			font-weight: 900;   
			margin-top: 5px;
			margin-bottom: 6px;
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				margin-bottom: 12px;
			}	
			& + p {
				margin-top: 8px;
			}
		}
		a.optional {			
			border: none !important;
			box-shadow: none !important;
			&:hover {
				background-color: transparent;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(18px);						
			}
		}
		ul {
			@media screen and (min-width: $break-large) {
				padding-left: 3px;				
				@include fontsize(18px);	
			}
		}
		.icon-arrow-right {
			font-size: 12px;
   			margin-left: 5px;
		}
		.optional {
			border: none !important;
			box-shadow: none !important;
			&:hover {
				background-color: transparent;
			}
		}
	}
}
section.two-column {
	.container {
	    margin-bottom: 15px;
	}
	h2 {
	    margin-bottom: 11px;
	    @media screen and (min-width: $break-large) {
	    	margin-bottom: 22px;				
	    }
	}
	p {
		margin-top: 9px;
		@media screen and (min-width: $break-large) {
			max-width: 900px;
			line-height: 1.7;				
		}
	}
	.row {
	    margin-top: 26px;
		@media screen and (min-width: $break-medium) {
			@include clearfix();
			margin-left: -6px;
			margin-right: -6px;   
		    margin-top: 15px;
		}
		@media screen and (min-width: $break-large) {
			margin-left: -28px;
			margin-right: -28px;
			margin-top: 28px;
		}
	}
	.column {
		margin-bottom: 25px;
		@media screen and (min-width: $break-medium) {	
			@include fontsize(16px);			
			width: calc(50% - 12px);
			float: left;
			margin-left: 6px;
			margin-right: 6px;
		}
		@media screen and (min-width: $break-large) {			
			width: calc(50% - 56px);
			margin-left: 28px;
			margin-right: 28px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		img {
			width: 100%;
			border-radius: 2px;
			@media screen and (min-width: $break-large) {
				margin-bottom: 20px;				
			}
		}
		p {
			@include fontsize(14px);   
		    margin-top: 5px; 
			line-height: 1.4;
			margin-bottom: 5px;
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				line-height: 1.65;						
			}
		}
	}
	a {
		@media screen and (min-width: $break-large) {
			@include fontsize(18px);						
		}
	}
	h3, h3 a {
		@include fontsize(18px);
		margin-top: 12px;
		margin-bottom: 3px;
		@media screen and (min-width: $break-medium) {
			@include fontsize(22px);
			margin-bottom: 10px;
		}	
		@media screen and (min-width: $break-large) {
			@include fontsize(28px);
			margin-bottom: 10px;
		}	
	}
	h4 {
		@include fontsize(14px);
		text-transform: uppercase;
		color: $color-grey-2;
		margin-bottom: 0;
		font-weight: 900;   
		margin-top: 12px;
		margin-bottom: 8px;
		@media screen and (min-width: $break-large) {
			@include fontsize(16px);
		}	
		& + p {
			margin-top: 10px;
		}
	}
	.icon-arrow-right {
		font-size: 12px;
		margin-left: 5px;
	}
	.optional {
		border: none !important;
		box-shadow: none !important;
		&:hover {
			background-color: transparent;
		}
	}
}
section.generic-block-cta {
	position: relative;
	margin-bottom: 28px;
	background-color: $color-off-white;
	@media screen and (min-width: $break-medium) {
	    margin-bottom: 31px;				
	}
	@media screen and (min-width: $break-large) {
		margin-bottom: 48px;				
	}
	&.yellow .container:before {
		background: $color-yellow;
	}
	&.blue .container:before {
		background: $color-blue-2;
	}
	.container {
		@include clearfix();
		position: relative;
		z-index: 1;
		padding: 22px 35px 25px;
		@media screen and (min-width: $break-medium) {
			display: flex;
			flex-direction: row;   
			padding: 33px 46px 24px;
		}
		@media screen and (min-width: $break-large) {
			padding: 12px 46px 37px;				
		}
		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 0;
			width: 400px;
			height: 100%;
			transform: skewX(3.4deg);
			opacity: 0;
			left: -400px;
			transition: 0.3s ease-out;
			transition: 1s cubic-bezier(0.11, 0.95, 0.51, 1.01);
			@media screen and (min-width: $break-medium) {
				transform: skewX(5.4deg);
			}
		}
		h2 {
			line-height: 1.2;    
			margin-bottom: 17px;
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 5px;				
			}
			@media screen and (min-width: $break-large) {
			    margin-bottom: 29px;				
			}
		}
		h3 {
			margin-top: 0;
			margin-bottom: 11px;
			color: $color-grey-2;
			@media screen and (min-width: $break-medium) {
				@include fontsize(22px);		 					
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);	    
				margin-bottom: 13px;	 					
			}
		}
		p {
		    margin-top: 5px;
		    @media screen and (min-width: $break-large) {
	    		line-height: 1.7;			
		    }
		}
		a {
			display: inline-block;
			max-width: 251px;
			margin: 0;
			text-align: center;
			transition: 0.3s;
			color: $color-grey-2 !important;
			border-color: $color-grey-2 !important;
			&:hover {
				color: $color-blue;
			}
			&:before {
				display: none;
			}
			& + a {
				margin-top: 15px;
			}
		}
		span.sub-title {
			font-weight: 900;
			color: $color-grey-2;
			display: block;
			@media screen and (min-width: $break-medium) {
				margin-top: 17px;    
				margin-bottom: 2px;
			}
			& + h4 {
				margin-top: 2px;
			}
		}
		img {
			width: 53%;
			float: left;   
			padding-right: 35px;
			@media screen and (min-width: $break-large) {
			    width: 51%;				
			}
			@media screen and (max-width: $break-large - 1px) {
				display: none;
			}
			& + .inner-content {
				@media screen and (min-width: $break-large) {
					padding-left: 50%;    
				    padding-top: 5px;
				}
			}
		}
		.content {
			@include clearfix();
		}
		.content-wrapper {
			@media screen and (min-width: $break-medium) {
				width: 	70%;
				padding-right: 50px;
			}
			a[href^="mailto:"] {
				text-align: left;
				box-shadow: none;
				border: none;
				white-space: nowrap;
				&:hover {
					background-color: transparent;
				}
				.icon-email {
					font-size: 19px;
					vertical-align: middle;
					margin-right: 7px;
					position: relative;
					top: -1px;
				}
			}
		}
		.resouce-column {   
			margin-top: 10px;
			@media screen and (min-width: $break-medium) {
				width: 30%;	    
				display: flex;
    			flex-direction: column;
			}
			@media screen and (min-width: $break-large) {
				margin-top: 0;				
			}
			.resources {
				@media screen and (min-width: $break-medium) {
					flex: 1 0 auto;
				    display: flex;
				    flex-direction: column;
				    justify-content: center;
				}	
			}
		}
	}
}
.accordion-block  {
	padding-top: 25px;
	h2 {
	    margin-bottom: 10px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 20px;
		}
	}
	p {
	    margin-top: 10px;
	    @media screen and (min-width: $break-large) {
	    	line-height: 1.65;				
	    }
	}
}
.accordion-wrapper {
	margin-right: -15px;
	margin-left: -15px;
	@media screen and (min-width: $break-medium) {
		margin-right: -30px;
		margin-left: -30px;
	}
	@media screen and (min-width: $break-large) {
		margin-right: 0;
		margin-left: 0;
		margin-top: 27px;
	}
	.accordion-controls {
		padding-left: 15px;
		@media screen and (min-width: $break-medium) {
			text-align: left;
			padding-left: 30px;
			padding-right: 30px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(14px);
			padding-left: 0;
			padding-right: 20px;
		}
		button {
			font-weight: 600;
			transition: 0.3s;
			color: $color-blue;
			&:hover {
				color: $color-blue-2;
			}
		}
		.open {
			@include fontsize(14px);		
			margin-right: 23px;
			@media screen and (min-width: $break-medium) {
				margin-right: 32px;
			}
			@media screen and (min-width: $break-large) {
				margin-right: 24px;
			}
			[class^="icon-"] {		
				display: inline;
				vertical-align: middle;
				margin-right: 12px;
				font-weight: normal;
				font-size: 9px;
				font-size: 13px;
				@media screen and (min-width: $break-large) {
					font-size: 15px;
				}
			}
		}
		.close {
			@include fontsize(14px);
			[class^="icon-"] {		
				display: inline;
				vertical-align: middle;
				margin-right: 12px;
				font-weight: normal;
				font-size: 9px;
				font-size: 13px;
				@media screen and (min-width: $break-large) {
					font-size: 15px;
				}
			}
		}				
	}
	> ul {
		@include clearfix();
		margin-top: 13px;
		margin-bottom: 0;
		padding-left: 0;
		@media screen and (min-width: $break-medium) {
			margin-top: 8px;
		}
		@media screen and (min-width: $break-large) {
			margin-top: 9px;
		}
		> li {
			position: relative;
			border-top: 2px solid $color-grey;
			border-bottom: 2px solid $color-grey;
			margin-bottom: -2px;
			padding-left: 0;
			list-style: none;
			@media screen and (min-width: $break-large) {
				border: 2px solid $color-grey;
			}
			@media screen and (max-width: $break-medium - 1px) {
				&:before {
					display: none;
				}				
			}
			&:before {
				display: none;
			}
			&.expanded {
				.toggle-accordion {					
					color: $color-blue-2;
					.icon-plus:before {
						content: "\e91d";
					}
				}
			}
			.accordion-title {
				font-weight: 600;
			}
			.toggle-accordion {		
				@include fontsize(16px);
				position: relative;
				font-weight: 600;
				padding-left: 15px;
				text-align: left;
				padding-top: 12px;
				padding-right: 45px;
				padding-bottom: 13px;
				line-height: 1.4;
				transition: 0.3s;
				width: 100%;
				color: $color-blue;
				@media screen and (min-width: $break-medium) {
					padding-left: 30px;
					padding-top: 14px;
					padding-bottom: 16px;
					padding-right: 90px;
				}
				@media screen and (min-width: $break-large) {
					padding-top: 18px;
					padding-bottom: 16px;
					padding-left: 20px;
					padding-right: 90px;
				}	
				&:hover {
					color: $color-blue-2;
				}
				[class^="icon-"] {		
					color: $color-blue-2;
					display: inline;
					vertical-align: middle;
					font-weight: normal;
					font-size: 16px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translate(0, -50%);
					font-weight: normal;
					@media screen and (min-width: $break-medium) {
						font-size: 16px;
						right: 30px;
					}
					@media screen and (min-width: $break-large) {
					    right: 23px;
						font-size: 17px;
					}
				}
			}
			.accordion-top {				
				[class^="icon-"] {	
					color: $color-blue-2;
					display: inline;
					vertical-align: middle;
					margin-right: 6px;
					font-weight: normal;
					font-size: 9px;
					@media screen and (min-width: $break-medium) {
						font-size: 13px;
					}
				}
			}
		}
	}
	.all-collapsed .close, .all-expanded .open {
		opacity: 0.5;
		pointer-events: none;
	}
	.more-info {
		display: none;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 17px;
		@media screen and (min-width: $break-medium) {
			padding-left: 30px;
			padding-right: 30px;
			padding-bottom: 26px;
		}	
		@media screen and (min-width: $break-large) {
			padding-left: 20px;
			padding-right: 90px;
			padding-bottom: 33px; 				
		}
		p:first-child {
			margin-top: 0;
		}
		p {
			@include fontsize(16px);
			font-weight: normal;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.accordion-top {
			@include fontsize(14px);
			font-weight: 700;
			color: $color-blue-2;
			transition: 0.3s;
			@media screen and (min-width: $break-large) {
				margin-top: 14px;
			}
			&:hover {
				color: $color-blue;
				.icon-arrow-up {
					color: $color-blue;
				}
			}
			.icon-arrow-up {
				font-size: 14px;
				@media screen and (min-width: $break-medium) {
					font-size: 14px;
				}
			}
		}
	}
}
section.three-column-events {	
    padding-top: 5px;
	.container {
		text-align: center;
	    padding-bottom: 5px;
	}
	h2 {
		margin-bottom: 11px;
		text-align: left;
		@media screen and (min-width: $break-large) {
			margin-bottom: 24px;			
		}
	}
	p {
		margin-top: 11px;
		text-align: left;
	}
	.row {
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 30px;    
		margin-top: 22px;
		@media screen and (min-width: $break-medium) {
			margin-left: -6px;
			margin-right: -6px;
			display: flex;
			flex-direction: row;
			margin-bottom: 14px;
		}
		@media screen and (min-width: $break-large) {
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 35px;    
			margin-bottom: 32px;
		}
	}
	.column {
		padding-left: 15px;
		padding-right: 15px;
		background-color: $color-blue-2;
		margin-bottom: 16px;
		color: #fff;
		padding: 30px 15px;
		min-height: 312px;
		display: flex;
		flex-direction: column;
		border-radius: 2px;
		@media screen and (min-width: $break-medium) {
			width: calc(33.33% - 12px);
			margin-left: 6px;
			margin-right: 6px;
			min-height: 202px;    
			padding: 19px 15px;
		}
		@media screen and (min-width: $break-large) {
			width: calc(33.33% - 30px);
			margin-left: 15px;
			margin-right: 15px;
			min-height: 312px;  
			padding: 31px 15px;
		}
		span:not(.last-word) {
			display: block;
			text-align: center;
			line-height: 1;
			&.month {
				@include fontsize(22px);
				font-weight: 500;
				letter-spacing: 4.71px;
				margin-bottom: 7px;
				text-transform: uppercase;
				@media screen and (min-width: $break-medium) {
					@include fontsize(14px);
				}	
				@media screen and (min-width: $break-large) {
					@include fontsize(22px);
				}	
			}
			&.day {
				@include fontsize(60px);
				font-weight: 900;
			    position: relative;
			    margin-bottom: 5px;
			    padding-bottom: 21px;
				@media screen and (min-width: $break-medium) {
					@include fontsize(34px);
					padding-bottom: 11px;
				}	
				@media screen and (min-width: $break-large) {
					@include fontsize(60px);
					padding-bottom: 20px;
				}
				&:after {
					content: '';
					display: block;
					width: 130px;
					height: 1px;
					background-color: #fff;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%,0);
					@media screen and (min-width: $break-medium) {
						width: 62px;				
					}
					@media screen and (min-width: $break-large) {
						width: 130px;				
					}
				}	
			}
			&.time {
				@include fontsize(18px);
				color: $color-yellow-2;
				font-weight: 700;
				margin-bottom: 7px;
				letter-spacing: 0.32px;
				@media screen and (min-width: $break-medium) {
					@include fontsize(14px);
				}	
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
					margin-bottom: 10px;
				}
			}
			&.location {
				@include fontsize(18px);
				font-weight: normal;
				letter-spacing: 0.32px;
				@media screen and (min-width: $break-medium) {
					@include fontsize(14px);
				}	
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
				}
			}
		}
		h3, h3 a {
			color: #fff;
			text-align: center;
			border: none !important;
			box-shadow: none !important;
			transition: 0.3s;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);
				margin-bottom: 8px;
			}	
			@media screen and (min-width: $break-large) {
				@include fontsize(24px);
				margin-top: 20px;
				margin-bottom: 16px;
				letter-spacing:-0.44px;
			}
			&:hover {
				background-color: transparent;
				color: $color-yellow;
			}
			.icon-arrow-round-right {
				display: inline-block;
				font-size: 13px;
				margin-left: 7px;
				@media screen and (min-width: $break-medium) {
					font-size: 8px;				
				}	
				@media screen and (min-width: $break-large) {
					font-size: 14px;				
				}	
			}
		}
	}
	.button {
		display: inline-block;
		margin-bottom: 11px;
	}
}
section.jump-in .news,
section.three-column-news .column {
	position: relative;
	display: flex;
	background-size: cover;
	background-position: center;
	@media screen and (min-width: $break-large) {
		//margin-top: 50px;
	}
	.overlay {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		background-color: $color-blue;
		opacity: 0.6;
	}
	.container {
		display: flex;
		flex-direction: column;
		color: #fff;
		position: relative;
		z-index: 1;
		padding-top: 12px;
		padding-bottom: 20px;
		width: 100%;
		@media screen and (min-width: $break-medium) {
			padding-left: 23px;
			padding-right: 23px;
		}	
		@media screen and (min-width: $break-large) {
		    padding-left: 42px;
			padding-right: 35px;   
			padding-top: 9px;   
			padding-bottom: 36px;
		}
		article {
			display: flex;
			flex: 1;
			height: 100%;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;
			z-index: 1;
			.date {
				color: $color-yellow-2;
				text-transform: uppercase;
				display: block;
			}
			h4, h3 {
				@include fontsize(18px);
				font-weight: 700;
				letter-spacing:0.23px;
				line-height: 1.2;
				margin-bottom: 5px;
				margin-top: 5px;
				text-transform: initial;
				margin-right: 0;
				@media screen and (min-width: $break-medium) {
					line-height: 1.4;
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(24px);
					line-height: 1.2;   
					margin-top: 11px;
				}	
				a {
					color: #fff;		
					border: none !important;
					box-shadow: none !important;
					transition: 0.3s;
					&:hover {
						background-color: transparent;
						color: $color-yellow;
					}
					.icon-arrow-round-right {
						font-size: 13px;
						@media screen and (min-width: $break-large) {
											
						}
					}
				}
			}
		}
	}
}
section.jump-in .news {

}
section.three-column-news {
	margin-bottom: 35px;
	text-align: center;
	h2 {
	    margin-bottom: 10px;
	    text-align: left;
	    @media screen and (min-width: $break-large) {
	    	margin-bottom: 23px;				
	    }
	}
	p {
		margin-top: 9px;
		text-align: left;
	}
	.row {
		margin-top: 21px;
		text-align: left;
		@media screen and (min-width: $break-medium) {
			display: flex;
			flex-direction: row;
			margin-left: -6px;
			margin-right: -6px;
			margin-bottom: 13px;
		}
		@media screen and (min-width: $break-large) {
			margin-left: -15px;
			margin-right: -15px;    
			margin-bottom: 34px;    
			margin-top: 35px;
		}
	}
	.column {
		background-color: $color-blue;
		min-height: 312px;
		margin-bottom: 16px;
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 2px;
		@media screen and (min-width: $break-medium) {
			width: calc(33.33% - 12px);
			min-height: 192px;
			margin-left: 6px;
			margin-right: 6px;
		}
		@media screen and (min-width: $break-large) {
			width: calc(33.33% - 30px);
			min-height: 312px;
			margin-left: 15px;
			margin-right: 15px;
		}
		.container {
			@media screen and (min-width: $break-medium) {
				padding-bottom: 15px;				
			}
			@media screen and (min-width: $break-large) {
				padding-left: 22px;
				padding-bottom: 26px;			
			}
		}
		.date {
			margin-bottom: 5px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);
				line-height: 1;
				margin-bottom: 0;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(18px);
			}
		}
		h1 {
			margin-bottom: 7px;
			@media screen and (min-width: $break-medium) {				
				line-height: 1 !important;				
			}
			a {
				@include fontsize(24px);
				line-height: 1;
				@media screen and (min-width: $break-medium) {
					@include fontsize(14px);
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(24px);
				}
				.icon-arrow-round-right {
					@media screen and (min-width: $break-medium) {
						@include fontsize(9px);
					}
					@media screen and (min-width: $break-large) {
						@include fontsize(14px);
					}
				}
			}
		}
		a:before {
			display: none;
		}
		.icon-arrow-round-right {
			font-size: 11px;
		}
	}
	.button {
		display: inline-block;
		text-align: center;
	}
}

section.carousel {
	margin-bottom: 35px;
	@media screen and (min-width: $break-large) {
		margin-bottom: 55px;				
	}
	h2 {
		margin-bottom: 11px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 23px;				
		}
	}
	p {
		margin-top: 11px;
		@media screen and (min-width: $break-large) {
			line-height: 1.65;				
		}
	}
	.carousel-wrapper {		
		margin-left: -15px;
		margin-right: -15px;
		margin-top: 21px;
		@media screen and (min-width: $break-medium) {
			margin-left: -30px;
			margin-right: -30px;
		}
		@media screen and (min-width: $break-large) {
			margin-left: 0;
			margin-right: 0;
			transition: 0.3s;
			margin-top: 31px;
		}
	}
	.carousel-slider {
		.top {
			position: relative;
			background-color: $color-blue;
		}
		.slick-arrow {
			position: absolute;
			top: 50%;
			transform: translate(0,-50%);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			border: 3px solid #fff;
			font-size: 17px;
			line-height: 35px;
			text-align: center;	
			color: #fff;
			transition: 0.3s;
			@media screen and (min-width: $break-medium) {
				width: 40px;
				height: 40px;
				font-size: 17px;
				line-height: 40px;				
			}	
			@media screen and (min-width: $break-large) {
				width: 50px;
				height: 50px;
				font-size: 19px;
				line-height: 50px;	
			}
			&:hover {
				color: $color-yellow;
				border-color: $color-yellow;
			}
		}
		.slick-prev {
			left: 10px;
			@media screen and (min-width: $break-medium) {
			    left: 27px;				
			}
		}
		.slick-next {
			right: 10px;
			@media screen and (min-width: $break-medium) {
				right: 26px;				
			}
		}
		img {
			width: 100%;
			height: auto;
			&.desktop {
				@media screen and (max-width: $break-medium - 1px) {
					display: none;			
				}
			}
			&.mobile {
				@media screen and (min-width: $break-medium) {
					display: none;			
				}
			}
		}
		.slick-dots {
			color: #fff;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%,0);
			padding-left: 0;   
			margin-bottom: 10px;
			@media screen and (min-width: $break-medium) {
				transform: none;
				left: auto;
				right: 20px;				
			}
			@media screen and (min-width: $break-large) {
				right: 24px;				
			}
			li {
				list-style: none;
				display: inline-block;
				padding: 0;
				margin: 0 6px;
				line-height: 0;
				@media screen and (min-width: $break-medium) {
					margin: 0 4px;				
				}
				@media screen and (min-width: $break-large) {
					margin: 0 6px;				
				}
				&:before {
					display: none;
				}
				&.slick-active button {
					background: #fff;
				}
				button {
					overflow: hidden;
					text-indent: 100%;
					display: block;
					width: 15px;
					height: 15px;
					border: 2px solid #fff;
					border-radius: 50%;
					line-height: 0;
					transition: 0.3s;
					@media screen and (min-width: $break-medium) {
						width: 10px;
						height: 10px;				
					}
					@media screen and (min-width: $break-large) {
						width: 15px;
						height: 15px;				
					}
					&:hover {
						background: #fff;
					}
				}
			}
		}
	}
	.carousel-slider-content {
		background-color: $color-blue;
		color: #fff;
		padding-right: 30px;
		padding-left: 30px;
	    padding-top: 26px;
		padding-bottom: 30px;
		@media screen and (min-width: $break-medium) {
		    padding-top: 19px;	    
		    padding-bottom: 14px;		
		}
		@media screen and (min-width: $break-large) {
			padding-top: 28px;				
		}
		span.h3 {
			@include fontsize(16px);
			text-transform: uppercase;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);						
			}		
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);					
			}
		}
		h3 {
			@include fontsize(24px);
			color: inherit;
			margin-top: 9px;
			margin-bottom: 7px;
			font-weight: 700;
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);
			}
		}
		p {
			@include fontsize(16px);
			margin-top: 7px;		
		}
	}
}
section.video-block {
	@media screen and (min-width: $break-large) {
		margin-bottom: 25px;				
	}
	> .container {
		overflow: hidden;    
		padding-top: 40px;
		h2 {
			margin-bottom: 11px;
			@media screen and (min-width: $break-large) {
				margin-bottom: 23px;				
			}
		}
		p {
			margin-top: 11px;
			@media screen and (min-width: $break-large) {
				line-height: 1.65;				
			}
		}
	}
	.video-wrapper {
		position: relative;
		background-color: $color-blue;    
		margin-top: 9px;
		&.video-playing {
			iframe {
				animation: fade-in 0.3s 0.3s ease forwards;
				z-index: 16;
				display: block;
			}
			.play-video, .video-poster {
				z-index: 1;
			}
		}
	}
	.video {
		position: relative;	
		iframe {
			opacity: 0;
			display: none;
		}	
	}
	.video-poster {
		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-blue, 0.34);
			z-index: 3;
			@media screen and (min-width: $break-medium) {
				background-color: rgba($color-blue, 0.64);
			}
		}
	}
	.play-video {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 3px solid #fff;
		color: #fff;
		z-index: 3;
		transition: 0.3s;
		@media screen and (min-width: $break-large) {
			width: 70px;
			height: 70px;				
		}
		&:hover {
			border-color: $color-yellow;
			color: $color-yellow;
		}
		.icon-play {
			text-align: center;
			font-size: 18px;
			position: relative;
		    left: 2px;
		    top: 2px;
		    @media screen and (min-width: $break-large) {
		    	font-size: 24px;
		    	top: 4px;				
		    }
		}
	}
	.content-wrapper {
		overflow: hidden;
		padding: 5px 30px 26px;	
		@media screen and (min-width: $break-medium) {
			padding: 0;				
		}	
		@media screen and (min-width: $break-large) {
			position: absolute;
		    left: 50%;
		    top: 0;
		    transform: translate(-50%,0);
		    width: 100%;
		    height: 100%;
		    max-width: 1280px;	
		    z-index: 2;		
		}		
	}
	.content {
		color: #fff;
		@media screen and (min-width: $break-medium) {
			position: absolute;
			background-color: transparent;	
			bottom: 0;
			left: 30px;
			z-index: 3;	
			padding-left: 0;
			max-width: 335px;
			padding-bottom: 10px;		
		}
		@media screen and (min-width: $break-large) {
			max-width: 600px;
			left: 50px;   
			padding-bottom: 17px;
		}
		h3 {
			color: inherit;
			margin-bottom: 10px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(22px);
				letter-spacing:-0.41px;						
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(32px);
				font-weight: 700;
				margin-bottom: 0;	
			}
		}
		p {
			margin-top: 11px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);						
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);
				margin-top: 9px;							
			}
			&:last-child {
			    margin-bottom: 25px;
			}
		}
	}
	.button {
		text-align: center;
		margin-top: 15px;
		display: block;
		max-width: 251px;
		margin-left: 0;	
		@media screen and (min-width: $break-medium) {
			position: absolute;
			right: 30px;
			bottom: 30px; 
			z-index: 3;	
			margin-top: 0;		
		}
		@media screen and (min-width: $break-large) {
			right: 50px;
		    bottom: 69px;			
		}
	}
}
section.stats {	
	text-align: center;
	> .container {
	    padding-top: 39px;
	    @media screen and (min-width: $break-medium) {
    	    padding-top: 25px;				
	    }
		&:after {
			margin-top: 35px;
		}
		h2 {
			text-align: left;
		}
		> p {
			text-align: left;
		}
	}
	.row {
		@media screen and (min-width: $break-medium) {
			display: flex;
			flex-direction: row;				
		}
		.column {
			text-align: left;
			@media screen and (min-width: $break-medium) {
				display: flex;
				margin-bottom: 0 !important; 				
			}
		}
	}
	&.full {
		@media screen and (min-width: $break-large) {
			margin-bottom: 53px;				
		}
		p {
			@media screen and (min-width: $break-medium) {
				@include fontsize(16px);						
			}
		}
		.row {
			margin-bottom: 30px;
			@media screen and (min-width: $break-medium) {
			    margin-top: 21px;				
			}
			@media screen and (min-width: $break-large) {
			    margin-bottom: 55px;				
			}
		}
		.column {
			background-color: $color-off-white;
			overflow: hidden;
			padding: 30px;
			width: 100%;
			@media screen and (min-width: $break-medium) {
				padding: 21px 30px 20px;
			}
			@media screen and (min-width: $break-large) {
				padding-top: 60px;
				padding-bottom: 60px;
				padding-right: 136px;			
			}
			.content {
				@media screen and (min-width: $break-medium) {
					display: flex;
					flex-direction: row; 
					align-items: center;				
				}				
			}
			.stat {
				@media screen and (min-width: $break-medium) {
				    min-width: 63px;
    				padding-right: 30px;		
					padding-bottom: 0;	
					text-align: right;						
				}
				@media screen and (min-width: $break-large) {
				    min-width: 194px;
					padding-right: 40px;	
				}
			}
			.stat-label {
				@include fontsize(14px);
				@media screen and (min-width: $break-medium) {
					margin-top: 0;
					margin-bottom: 0;
					line-height: 1.15;				
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
					line-height: 1.38;
					font-weight: normal;						
				}		
			}
		}
	}
	&.two-column {
		.row {
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: 30px;
			@media screen and (min-width: $break-medium) {
				margin-left: -7px;
				margin-right: -7px;	
				display: flex;
				flex-direction: row;			
			}
			@media screen and (min-width: $break-large) {
				margin-left: -28px;
				margin-right: -28px;
				margin-bottom: 55px;	
			}
		}
		.column {
			background-color: $color-off-white;
			overflow: hidden;
			padding: 30px;
			@media screen and (min-width: $break-medium) {
				width: calc(50% - 14px);
				margin-left: 7px;
				margin-right: 7px;
				min-height: 161px;
				padding: 23px 25px 11px;			
			}
			@media screen and (min-width: $break-large) {
				width: calc(50% - 54px);
				margin-left: 28px;
				margin-right: 28px;
				min-height: 281px;
				padding: 43px 31px 11px;
			}
			.stat {
				@media screen and (min-width: $break-medium) {
					padding-bottom: 12px;				
				}
			}
		}
	}
	&.three-column {
		.row { 
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: 37px;
			@media screen and (min-width: $break-medium) {
				margin-left: -6px;
				margin-right: -6px;	
				display: flex;
				flex-direction: row;			
			}
			@media screen and (min-width: $break-large) {
				margin-left: -15px;
				margin-right: -15px;
				margin-bottom: 55px;	
			}
		}
		.column {
			background-color: $color-off-white;
			overflow: hidden;
		    padding: 42px 30px 20px;
		    margin-bottom: 16px;
			@media screen and (min-width: $break-medium) {
				width: calc(33.33% - 12px);
				margin-left: 6px;
				margin-right: 6px;
				min-height: 202px;
				padding: 23px 25px 11px;				
			}
			@media screen and (min-width: $break-large) {
				width: calc(33.33% - 30px);
				margin-left: 15px;
				margin-right: 15px;
				min-height: 312px;
				padding: 43px 31px 11px;
			}			
		}
	}	
	.stat {
		@include fontsize(95px);
		font-family: 'Roboto Condensed', sans-serif;		
		color: $color-red;
		font-weight: 900;
		line-height: 1;
		display: block;
		letter-spacing: -4.5px;
		@media screen and (min-width: $break-medium) {
			@include fontsize(50px);
			padding-bottom: 37px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(95px);
			padding-bottom: 19px; 						
		}
	}
	.column .stat-label {
		@include fontsize(24px);
		line-height: 1.3;
		margin-top: 10px;
		margin-bottom: 10px;
		@media screen and (min-width: $break-medium) {
			@include fontsize(16px);
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(24px); 						
		}
	}	
}
section.pathway {
	background-color: $color-off-white;
	overflow: hidden;
	margin-top: 25px;
	@media screen and (min-width: $break-medium) {
	    //padding-top: 15px;			
	}
	> .container {
		padding-top: 20px;
		position: relative;
		@media screen and (min-width: $break-large) {
		    padding-top: 28px;	
		    padding-bottom: 50px;			
		}
		&:before {
			@media screen and (min-width: $break-large) {
				display: block;
				content: '';
				position: absolute;
				top: 0;
				width: 400px;
				height: 100%;
				opacity: 1;
				right: -264px;
				transition: 0.3s ease-out;
				transition: 1s cubic-bezier(0.11, 0.95, 0.51, 1.01);
				background-color: $color-blue-2;
				transform: skewX(-7.4deg);
			}
		}
		h2 {
			margin-bottom: 11px;
			@media screen and (min-width: $break-large) {
				margin-bottom: 23px;				
			}
		}
		p {
			margin-top: 11px;
			@media screen and (min-width: $break-large) {
				line-height: 1.65;
				max-width: 900px;				
			}
		}
	}
	.pathway-slider-wrapper {
		background-color: #fff;
		margin-left: -15px;
		margin-right: -15px;
		position: relative;
		@media screen and (min-width: $break-medium) {
			margin-top: 22px;				
		}
		@media screen and (min-width: $break-large) {	
			margin-left: 0;
			margin-right: 0;   
			margin-top: 33px;			
		}
	}
	.pathway-slider-header {
	    padding-left: 37px;
   		padding-right: 37px;
		position: relative;
		padding-top: 25px;
		@media screen and (min-width: $break-medium) {
			padding-left: 15px;
			padding-right: 15px;    
			padding-top: 30px;
		}
		@media screen and (min-width: $break-medium) {
			padding-left: 50px;
			padding-right: 50px;
			padding-top: 36px;
		}
		.indicator {
			width: 100%;
			height: 2px;
			background-color: $color-grey;
		}
		.slick-list {
			margin-top: -3px;
			padding-top: 3px;
			.slick-track {
				@media screen and (min-width: $break-medium) {
					width: 100% !important;	
					transform: none !important;								
				}
			}
		}
		.slide {
			@include fontsize(12px);
			position: relative;		
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 0.38px;
			padding: 7px 15px 9px 0;
			color: $color-blue-2;
			transition: 0.3s;
			cursor: pointer;
			@media screen and (min-width: $break-medium) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(14px);
				padding-top: 4px;
			}
			&:nth-child(1) {
				padding-left: 0;
			}
			&:hover {
				color: $color-blue;
			}
			&:before {
				display: block;
				content: '';
				height: 3px;
				background-color: transparent;
				transition: 0.3s;
				transition: width 4s;
				position: absolute;
				top: -3px;
				left: 0;
				width: 100%;
			}
		}
		.slick-current {
			color: #000;
			&:before {
				background-color: $color-yellow;
			}
		}
		/*.show-indicator:before {
			width: 100%;
			transition: width 0s !important;
			background-color: $color-yellow;
		}*/
		.slick-arrow {
			position: absolute;
			top: 32px;
    		font-size: 17px;
    		&.slick-disabled {
    			display: none !important;
    		}
		}
		.slick-prev {
			left: 9px;
		}
		.slick-next {
			right: 9px;
		}
	}
	.pathway-slider-body {
		.slide {
			padding: 0 15px 15px;
			@media screen and (min-width: $break-medium) {
				padding-left: 50px;
				padding-right: 50px;
			}
			&:not(.slick-active) {
				visibility: hidden;
			}
		}
		table {
			min-width: 100%;
			border-top: 1px solid $color-grey-4;
		    margin-bottom: 35px;
			caption { 
				@include fontsize(14px);
				text-transform: uppercase;
				color: $color-blue;
				font-weight: 900;
				text-align: left;
				margin-bottom: 7px;
				@media screen and (min-width: $break-medium) {
					@include fontsize(16px);
				}		
			}
			th {
				padding-left: 0;
			}
			tr {
				border-bottom: 1px solid $color-grey-4;
				&:nth-child(even) {
					background-color: $color-off-white;
				}
				td {
					@include fontsize(12px);
					padding: 7px;	
					color: #000;
					font-weight: normal;
					font-style: italic;	
					@media screen and (min-width: $break-medium) {
						@include fontsize(16px);
						padding: 4px 4px 4px 12px;
					}
					&:first-child {
						width: 80px;
						@media screen and (min-width: $break-medium) {
							width: 120px;				
						}
						@media screen and (min-width: $break-large) {
							width: 105px;				
						}
					}
				}
			}
		}
		h3 {    
			margin-top: 18px;
			margin-bottom: 17px;
			@media screen and (min-width: $break-medium) {
			    margin-top: 13px;				
				margin-bottom: 25px;				
			}
			@media screen and (min-width: $break-large) {
				margin-bottom: 35px;    
				margin-top: 32px;				
			}
		}
		.content {
			&.two-column {
				@media screen and (min-width: $break-large) {
					display: flex;
					flex-direction: row;				
				}
				.column {
					@media screen and (min-width: $break-large) {
						width: 50%;				
					}
					&:nth-child(1) {
						padding-right: 60px;
						margin-bottom: 20px;
						@media screen and (min-width: $break-medium) {
							padding-right: 0;
						}
						@media screen and (min-width: $break-large) {
							padding-right: 50px;				
						}
					}
					&:nth-child(2) {
						@media screen and (min-width: $break-large) {
							padding-left: 50px;				
						}
					}
				}
			}
			.column {
				p:nth-child(1) {
					margin-top: 0;
				}
				p {
				    margin-bottom: 11px;
    				margin-top: 11px;
    				@media screen and (min-width: $break-large) {
    					@include fontsize(16px);						
    				}
				}
			}
		}
	}
}
.pagination {
	margin-top: 25px;
	margin-bottom: 25px;
	@media screen and (min-width: $break-large) {
	    //width: 440px !important;
	    margin-right: 38px;				
	}
	nav {
		width: 100%;
		text-align: left;
	}
	ul {
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 0;
		li {
			list-style: none;
			&:before {
				display: none;
			}
		}
	}
	.dataTables_paginate {
	    @media screen and (min-width: $break-medium - 1px) {
			display: flex;
		    flex-direction: row;
		    align-items: center;	    					
	    }
	    > span {
		    /*@media screen and (min-width: $break-medium - 1px) {
		    	flex: 1 0 auto;
	    	    display: flex;
				flex-direction: row;
				justify-content: space-around;		    					
		    }*/
	    }
	}
	a,
	.page-item {
		@include fontsize(18px);
		display: inline-block;
		cursor: pointer;
		padding: 5px;		
		color: #000;
		margin-left: 5px;
		margin-right: 5px;
		font-weight: normal;
		border: none !important;
		box-shadow: none !important;
		transition: 0.3s !important;
		&:before {
			display: none !important;
		}
		&:hover {
			color: $color-blue-2 !important;
			border-color: $color-blue-2 !important;
			background-color: transparent !important;
		}
		&.current {
			color: $color-blue;
			font-weight: 700;
			border-bottom: 4px solid $color-blue !important;    
			padding-bottom: 0px;
			margin-top: 3px; // make up for the border pushing the number up
			&:hover {
				border-color: $color-blue-2 !important;
			}		
		}
	}
	.page-item {
		padding: 0;
		@media screen and (min-width: $break-medium) {
		    margin-left: 7px;
			margin-right: 7px;				
		}	
		@media screen and (min-width: $break-large) {
		    margin-left: 12px;
		    margin-right: 12px;				
		}	
		> a {
			margin: 0;    
			line-height: 1;
    		padding-bottom: 1px;
		}
	}
	.previous,
	.next {
		width: 45px;
		height: 45px;
		line-height: 45px;
		border-radius: 2px;
		border: 2px solid $color-grey-5 !important;
		font-size: 19px;
		display: inline-block;
		line-height: 45px;
		vertical-align: middle;
		padding: 0;
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		> a {
			width: 45px;
			height: 45px;
			line-height: 45px;
			margin: 0;
			padding: 0;
		}
	}
	.previous {
		@media screen and (min-width: $break-medium) {
		    margin-right: 10px;				
		}
		span {
			position: relative;
			left: -2px;
		}
	}
	.next { 
		@media screen and (min-width: $break-medium) {
		    margin-left: 10px;				
		}
		span {
			position: relative;
			right: -2px;
		}
	}
}
.dataTables_info {
	color: $color-blue;
}
.tags {
	margin-top: 56px;
	h2 {
		@include fontsize(16px);
		font-weight: 700;
		@media screen and (min-width: $break-large) {
			@include fontsize(18px);						
		}		
	}
	ul {
		padding-left: 0;
		li {
			@include fontsize(15px);	
			list-style: none;
			padding-left: 0;
			display: inline-block;
			color: $color-blue-4;
			background-color: $color-off-white;
			text-align: center;	
			height: 40px;
			vertical-align: middle;
			margin-bottom: 3px;
			&:before {
				display: none;
			}
			a { 
				display: block;
				padding-left: 11px;
				padding-right: 11px;
				line-height: 40px;
				vertical-align: middle;
				box-shadow: none !important;
				border-bottom: none !important;
				transition: 0.3s !important;
				color: $color-blue-4;
				&:hover {
					background-color: transparent !important;
					color: $color-blue;
				}
				&:before {
					display: none !important;
				}
			}
		}
	}
}
section.generic-content {
	margin-bottom: 35px;
	
	h2 {
		letter-spacing:-0.42px;
		@media screen and (min-width: $break-large) {
		    margin-bottom: 25px;				
		}
	}
	h3 {
		@media screen and (min-width: $break-large) {
			margin-bottom: 25px;				
		}
	}
	h4 {
		margin-bottom: 11px;
		@media screen and (min-width: $break-large) {
		    margin-bottom: 23px;				
		}
	}
	h5 {
		margin-bottom: 11px;
	}
	p {
		margin-top: 11px;
		@media screen and (min-width: $break-large) {
			line-height: 1.65;				
		}
	}
	ul {
	    overflow: hidden;
	}
}
body.degree-detail {
	header#page-title {
	    margin-bottom: 32px;
	    @media screen and (min-width: $break-large) {
    	    margin-bottom: 48px;				
	    }
	}
	section.pathway {
		padding-bottom: 25px;
	    margin-bottom: 61px;
	    @media screen and (min-width: $break-large) {
    	    padding-bottom: 49px;
   			margin-bottom: 100px;				
	    }		
	}
	section.generic-block-cta {
		margin-bottom: 49px;
		@media screen and (min-width: $break-large) {
		    margin-bottom: 82px;				
		}
	}
}
body.audience-motivation {
	header#page-title {
		margin-bottom: 0;
	}
	section.photo-grid {
		margin-top: 0;
	}
	section.office {
		@media screen and (min-width: $break-large) {
			margin-bottom: 55px;				
		}
	}
}
main > article section:last-child {
	// margin-bottom: 80px;
	// @media screen and (min-width: $break-large) {
	// 	margin-bottom: 101px;				
	// }
	> .container:after {
		display: none;
	}
}
body.directory-listing {
	header#page-title {
		margin-bottom: 0;
		@media screen and (min-width: $break-medium) {
			background-color: $color-off-white;
			padding-bottom: 120px;
			margin-bottom: -120px;
		}
		@media screen and (min-width: $break-large) {
			padding-bottom: 150px;
			margin-bottom: -150px;
		}
		.container {
		    padding-top: 131px;
	        margin-bottom: 18px;
	        @media screen and (min-width: $break-medium) {
        	    padding-top: 141px;	
        	    margin-bottom: 0;
	        }
	        @media screen and (min-width: $break-large) {
        	    padding-top: 168px;
        	    margin-bottom: 17px;
	        }
	        h1 {
	        	@media screen and (min-width: $break-medium) {
	        		margin-bottom: 0;
	        	}
	        }
		}
	}
	.table-container {
		overflow: hidden;
	}
	#directory + .results {
		margin-bottom: 0;
	}
}
#directory { 	
	margin-top: 3px;
	margin-bottom: 20px;
	border-top: 0; 
	border-spacing: 0; 
  	border-collapse: collapse; 
	width: 100% !important;
	@media screen and (max-width: 827px) {
		margin-top: 30px;
	}
	@media screen and (min-width: $break-medium) {
		margin-top: 47px;				
	}
	@media screen and (min-width: $break-large) {
		border-top: 0 !important;	    
		margin-top: 59px;			
	}
	thead {
		@include hideText();
	}
	tbody {
		border-top: none;
		tr {
			overflow: hidden;	
			border-bottom: 0;	
			display: block;
			margin-bottom: 10px;
			border-radius: 2px;
			background-color: $color-off-white;
			border-bottom: 10px solid $color-white;
			
			@media screen and (min-width: $break-large) {
				display: table-row;
			}
			td {
				@include fontsize(16px);
				padding: 14px 15px 15px 12px;
				padding-left: 22px;
				display: block;
				line-height: 1.8;
				@media screen and (max-width: $break-medium - 1px) {
					padding: 5px 15px 4px 15px;				
				}
				@media screen and (max-width: 827px) {
					padding: 4px 15px 4px 12px;
				}
				@media screen and (min-width: $break-medium) {
					padding-left: 30px;				
					width: 50%;
					float: left;			
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(20px);
					padding-left: 12px;				
					padding-bottom: 13px !important;
					display: table-cell;
					float: none !important;
				}
				&:nth-child(1) {
				    padding-bottom: 1px;
					padding-top: 21px;
					a {
						@include fontsize(18px);
						@media screen and (min-width: $break-large) {
							@include fontsize(22px);
						}
					}
					@media screen and (min-width: $break-large) {
						width: 41%;			   
						padding-left: 44px;
					    padding-top: 26px;
					}
					.position {
					    margin-bottom: 6px;  
						display: block;
						@media screen and (min-width: $break-large) {
							@include fontsize(20px);						
						}
					}		
				}
				&:nth-child(2) {
					padding-bottom: 0;
					@media screen and (min-width: $break-medium) {
				        padding-top: 21px;
				        padding-left: 0;
				        padding-bottom: 0;				
					}
					@media screen and (min-width: $break-large) {
						width: 23%;	
					    padding-top: 26px;			
					}
					.location {
						@media screen and (min-width: $break-medium) {
							margin-top: 0;				
						}
					}
				}
				&:nth-child(3) {
					padding-top: 0;
				    padding-bottom: 27px;
					@media screen and (min-width: $break-medium) {
						clear: both;
						float: right;
						padding-left: 0;
						padding-top: 0;			
					}
					@media screen and (min-width: $break-large) {
						width: 36%;
						clear: none;
						padding: 26px 15px 15px 12px;				
					}
				}				
				.icon-arrow-right {
					font-size: 13px;
		    		@media screen and (min-width: $break-large) {
						font-size: 16px;
		    		}
				}
				.icon-email {
					font-size: 20px;
					vertical-align: middle;
					position: relative;
					top: -1px;
					margin-right: 5px;
				}
				h2 {
					margin: 0;
				}
				a {
					font-weight: 600;
					box-shadow: none;
					border: none;
					transition: 0.3s !important;
					color: $color-blue-4;
					&:hover {
						background-color: transparent;
						color: $color-blue;
					}
					&[href^="mailto:"] {
					    margin-top: 2px;
						display: block;
						word-break: break-all;
					}
				}
			}
		}
	}
}
.dataTables_wrapper,
#news-list,
#our-stories {
	.results {    
		border-bottom: 2px solid #ddd;    
		padding-bottom: 29px;
		@media screen and (min-width: $break-medium) {
			display: flex;
			flex-direction: row;	
		    margin-bottom: 45px;
		    padding-bottom: 5px;		
		}
		@media screen and (min-width: $break-large) {			
			padding: 0;	   
			padding-bottom: 15px;			
		}
		.showing {
			display: flex;
			flex-direction: row;
			align-items: center;
			@media screen and (min-width: $break-medium) {
				margin-bottom: 0;
				width: 50%;	    
				flex: 1 0 auto;			
			}
		}
		.pagination {
			text-align: center;
			@media screen and (min-width: $break-medium) {
				margin-left: auto;
				display: flex;	
				width: 100%;						
			}
		}
	}
	.dataTables_info {	
		@include fontsize(16px);		
		margin-left: auto;
		@media screen and (min-width: 550px) { 
			@include fontsize(18px);
		}
		@media screen and (min-width: $break-medium) {	
			margin-left: 17px;	
		}
		@media screen and (min-width: $break-large) {
			margin-left: auto;				
		}
	}
	.dataTables_length {    
		min-width: 140px;
		@media screen and (min-width: 550px) { 
			min-width: 170px;
		}
		@media screen and (min-width: $break-medium) {
			width: 150px;
			margin-left: auto;			
		}
		@media screen and (min-width: $break-large) {
			margin-left: 0;				
		}
		select {			
			height: 45px;
			padding: 5px 29px 3px 5px;
			margin-bottom: 0;
			border-radius: 2px;
			border: 2px solid $color-grey-5 !important;
			color: #000;
			@media screen and (max-width: $break-large - 1px) {
				background-position: right 13px top 13px;
				background-size: 8px auto;									
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				padding: 7px 35px 10px 7px;
				height: 45px;
				width: 100%;
			}
		}
	}
}
#news-list,
#our-stories {
	.results {
		border-bottom: none;
		border-top: 2px solid #ddd;
		@media screen and (min-width: $break-medium) {
		    margin-top: 50px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-top: 69px;				
		}
	}
	.pagination {
		@media screen and (min-width: $break-medium) {
		    margin-top: 28px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-top: 62px;    
		    margin-bottom: 35px;				
		}
		nav {
			text-align: center;
			margin-bottom: 12px;
			@media screen and (min-width: $break-medium) {
				margin-bottom: 0;				
			}
			@media screen and (min-width: $break-large) {
				width: auto;
				margin-right: 38px;				
			}
		}
	}
}
.directory-filters {
	@include clearfix();
	margin-bottom: 3px;
	background-color: $color-blue;
	border-radius: 2px;
	color: #fff;
	padding: 22px 15px 10px;
	position: relative;
	@media screen and (min-width: $break-medium) {
		margin-top: 0;
	    margin-bottom: 5px;
		margin-left: -15px;
		margin-right: -15px;				
	}
	@media screen and (min-width: $break-large) {
		margin-left: 0;
		margin-right: 0;    
		margin-bottom: 14px;
		padding: 40px 40px 15px;
	}
	h2 {
		color: #fff;
		margin-top: 0;    
		margin-bottom: 22px;
		@media screen and (min-width: $break-medium) {
		    margin-bottom: 19px;				
		}
		@media screen and (min-width: $break-large) {
			margin-bottom: 23px;				
		}
	}
	#filtered-results {
		float: left;
	    margin-top: 20px;
		font-weight: 300;
		@media screen and (min-width: $break-medium) {
			margin-top: 0;
			clear: both;

		}
	}
	.reset-button {
		@include fontsize(14px);
		position: absolute;
		top: 20px;
		right: 15px;
		color: #fff;
		border: none !important;
		box-shadow: none !important;
		transition: 0.3s !important;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing:-0.33px;
		@media screen and (min-width: $break-large) {
			@include fontsize(16px);
		    top: 44px;
			right: 40px;				
		}			
		&:hover {
			background-color: transparent !important;
			color: $color-yellow;
		}
		.icon-close-square {
			font-size: 20px;
			margin-right: 6px;
			vertical-align: middle;
			position: relative;
    		top: -2px;
    		@media screen and (min-width: $break-large) {
    			font-size: 21px;
   				margin-right: 7px;				
    		}	
		}
		.filter-control-label-mobile {
			@media screen and (min-width: $break-large) {
				display: none;				
			}
		}
		.filter-control-label {
			display: none; 
			@media screen and (min-width: $break-large) {
				display: inline-block;				
			}
		}
	}
	.filter {
		width: 100%;
		margin-right: 15px;
		@media screen and (min-width: $break-medium) {	
			float: left;
			margin-right: 0;
			width: calc(33.33%);
			padding-right: 10px;			
		}
		@media screen and (min-width: $break-large) {
			padding-right: 14px;				
		}
		&.type {
			@media screen and (min-width: $break-large) {
				max-width: 240px;				
			}	
		}
		&.dept {	    
			margin-bottom: 16px;
			@media screen and (min-width: $break-medium) {
				margin-bottom: 0;				
			}	
			@media screen and (min-width: $break-large) {
				max-width: 240px;				
			}
		}
		&.search {	
			@media screen and (min-width: $break-medium) {
				padding-left: 30px;				
			}
			@media screen and (min-width: $break-large) {
				float: right;				
			}
		}
		label {
			@include fontsize(15px);
			display: block;
			font-weight: 600;	
		    margin-bottom: 2px;
			@media screen and (min-width: $break-medium) {
				@include fontsize(14px);
				margin-bottom: 0;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				margin-bottom: 3px;						
			}
		}
		select {
			height: 40px;	
			border-radius: 4px;
			padding: 5px 29px 8px 5px;
			color: #000;
			margin-bottom: 16px;
			max-width: 100% !important;
			@media screen and (max-width: $break-large - 1px) {
				background-position: right 13px top 13px;
				background-size: 8px auto;						
			}
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 21px;				
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				padding: 5px 35px 5px 7px;
				height: 40px;
				width: 100%;
			}
		}
	}
	#searchFilter {
		color: #000;
		height: 40px;
		width: 100%;
		padding: 5px 29px 8px 10px;
		border-radius: 4px;
		border: none;
		@media screen and (min-width: $break-large) {
			@include fontsize(16px);						
		}
	}
}
body.our-stories-listing,
body.news-story-listing {
	header#page-title {
		margin-bottom: 0;
		@media screen and (min-width: $break-medium) {
			margin-bottom: 40px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-bottom: 60px;				
		}
		.container {
			@media screen and (max-width: $break-medium - 1px) {
			    padding-bottom: 11px;				
			}
			@media screen and (min-width: $break-large) {
			    padding-top: 165px;
			    padding-bottom: 8px;				
			}
		}
	}
	section { 
		article {
			@include clearfix();
		}
		img {
			border-radius: 2px;
		}	
		a {
			border-bottom: none;
			box-shadow: none;
			transition: 0.3s !important;
			&:hover {
				background-color: transparent;
				color: $color-blue;
			}
			.icon-arrow-right {
				font-size: 12px;
			}
		}
		article:first-child:not(.firstPost) {
			margin-top: 40px;
		}	
		article.firstPost {
			background-size: cover !important;
			background-position: center !important;
			color: #fff;
			overflow: hidden;
			position: relative;
			margin-bottom: 40px;
			@media screen and (max-width: $break-medium - 1px) {
				background-image: none !important;				
			}
			@media screen and (min-width: $break-medium) {	
				background-color: $color-blue !important;
				&:before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					z-index: 1;
				}				
			}
			.mobile {
				background-color: $color-blue !important;
				background-size: cover !important;
				background-position: center !important;
				@media screen and (min-width: $break-medium) {
					background-image: none !important;	
					background-color: transparent !important;			
				}
				&:before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					z-index: 1;
				}
			}
			.container {
				position: relative;
				z-index: 1;
			}
			h2 {
				@include fontsize(20px);
				margin-top: 9px;
				margin-bottom: 5px;
				letter-spacing:-0.35px;
				@media screen and (min-width: $break-large) {
					@include fontsize(24px);
				}		
			}
			a {	
				color: #fff;
			}
			.quote {
				@include fontsize(16px);
				line-height: 1.4;
				margin-bottom: 10px;		
			}
			.excerpt {
			    line-height: 1.39;
			    margin-bottom: 9px;
			}
			.sub-title {
				@include fontsize(16px);
				margin-top: 5px;
			}
		}
		@at-root {
			body.our-stories-listing section article.firstPost {
				text-align: center;
				@media screen and (min-width: $break-medium) {
					text-align: left;				
				}
				.mobile {	
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: flex-end;
					position: relative;
					z-index: 1;
					min-height: 439px;
					overflow: hidden;	
					@media screen and (min-width: $break-medium) {
						height: 520px;		
					}
					@media screen and (min-width: $break-large) {
						height: 680px;		
					}
					&:before {
						background-color: rgba(#000, 0.35);
					}
				}
				.container {
					padding: 226px 40px 13px;
					@media screen and (min-width: $break-medium) {
						padding: 25px 50px 38px;
						margin: 0;					
					}
					@media screen and (min-width: $break-large) {
						margin: 0 auto;
    					width: 100%;			
					}
				}
				.content {
					@media screen and (min-width: $break-medium) {
						padding-left: 0;			
					}
					.quote {
						@media screen and (min-width: $break-medium) {
							@include fontsize(20px);
							max-width: 307px;
							font-weight: normal;
							line-height: 1.55;						
						}
						@media screen and (min-width: $break-large) {
							@include fontsize(34px);
							max-width: 523px;    
							line-height: 1.38;
						}
					}
					h2 {
						@media screen and (min-width: $break-medium) {
							@include fontsize(20px);
							margin-bottom: 9px;	
							margin-top: 40px;					
						}
						@media screen and (min-width: $break-large) {
							@include fontsize(24px);   
							margin-top: 34px;
						}
					}
					a:hover {
						color: $color-yellow;
					}
					.sub-title {
						font-weight: normal !important;
						@media screen and (min-width: $break-medium) {
							@include fontsize(16px);						
						}
						@media screen and (min-width: $break-large) {
							@include fontsize(20px);
						}
					}
				}
			}
		}
		@at-root {
			body.news-story-listing section article.firstPost {				
				display: flex;
				height: 100%;
				flex-direction: column;
				justify-content: flex-end;
				position: relative;
				z-index: 1;
				min-height: 312px;
				@media screen and (min-width: $break-medium) {
					height: 356px;	
					margin-left: 30px;
    				margin-right: 30px;	
    				padding-bottom: 21px;
    				&:before {
						background-color: rgba($color-blue, 0.6);
					}			
				}	
				@media screen and (min-width: $break-large) {
					height: 578px;
					padding-bottom: 35px;
					margin-left: 50px;
    				margin-right: 50px;
    				margin-bottom: 60px;					
				}	
				.mobile {		
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: flex-end;
					position: relative;
					z-index: 1;
					min-height: 312px;	
					@media screen and (min-width: $break-medium) {	
						min-height: 0;		
					}
					&:before {
						background-color: rgba($color-blue, 0.6);
						@media screen and (min-width: $break-medium) {
							display: none;				
						}
					}				
				}
				.container {
					padding-top: 23px;
					padding-bottom: 13px;
					margin: 0;
					@media screen and (min-width: $break-medium) {
						padding-bottom: 0;
						padding-left: 30px;
						padding-right: 30px;				
					}
					@media screen and (min-width: $break-large) {
					    padding-left: 50px;
    					padding-right: 50px;
    					margin: 0 auto;
    					width: 100%;				
					}	
				}
				.content {
					@media screen and (min-width: $break-medium) {
						padding-left: 0 !important;				
					}
				}
				.post-date {
					@include fontsize(18px);
					color: $color-yellow-2;
					display: block;
					@media screen and (min-width: $break-large) {
						@include fontsize(20px);
					}
				}
				h2 {
					@include fontsize(24px);
					font-weight: 700;
					letter-spacing:0.23px;
					line-height: 1.2;
					margin-bottom: 5px;
					margin-top: 8px;
					@media screen and (min-width: $break-medium) {
						font-size: 32px !important;
						font-size: 2rem !important;
						line-height: 1.4;
						margin-top: 17px;
						margin-bottom: 10px;
					}
					@media screen and (min-width: $break-large) {
						font-size: 42px !important;
    					font-size: 2.625rem !important;
						line-height: 1.2;   
						margin-top: 21px;    
						margin-bottom: 22px;
					}	
					a {
						color: #fff;		
						border: none !important;
						box-shadow: none !important;
						transition: 0.3s;
						&:hover {
							background-color: transparent;
							color: $color-yellow;
						}
						.icon-arrow-right {
							@media screen and (min-width: $break-large) {
								@include fontsize(24px);				
							}		
						}
					}
				}
				.excerpt  {
					@include fontsize(14px);		
					color: #000;
					padding-left: 15px;
					padding-right: 15px;
					line-height: 1.37;
					margin-top: 16px;
					@media screen and (min-width: $break-medium) {
						@include fontsize(16px);	
						color: #fff;
						position: relative;
						z-index: 2;	    
						padding-left: 30px;
    					padding-right: 30px;
    					margin-top: 0;	
    					max-width: 75%;		
					}
					@media screen and (min-width: $break-large) {
						@include fontsize(18px);    
						padding-left: 50px;
    					padding-right: 50px;
    					margin-left: auto;
    					margin-right: auto;
   						width: 100%;
   						max-width: 1280px;						
					}
				}
			}
		}
		article {    
			margin-bottom: 10px;
			@media screen and (min-width: $break-medium) {
			    margin-bottom: 14px;				
			}
			@media screen and (min-width: $break-large) {
			    margin-bottom: 20px;				
			}
			figure, img {
			    margin-bottom: 10px;
			}
			.quote {
				@include fontsize(14px);		
				line-height: 1.45;
				@media screen and (min-width: $break-medium) {
					margin-top: 0;	    
					margin-bottom: 23px;			
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
					margin-bottom: 29px;
				}
			}
			h2 {
				@include fontsize(18px);
			    margin-bottom: 8px;
			    letter-spacing:-0.33px;
			    margin: 0;
				@media screen and (min-width: $break-large) {
					@include fontsize(22px);    
					margin-bottom: 17px;
				}
			    a {
			    	transition: 0.3s !important;
			    	box-shadow: none !important;
			    	border-bottom: none !important;
			    	&:hover {
			    		background-color: transparent;
			    		color: $color-grey-2;
			    	}
			    }
			}
			.container {
				@include clearfix();
				@media screen and (min-width: $break-medium) {
					padding-left: 45px;
    				padding-right: 45px;				
				}	
				@media screen and (min-width: $break-medium) {
					padding-left: 50px;
    				padding-right: 50px;
				}		
			}
			.content {
				@media screen and (min-width: $break-medium) {
				    padding-left: 218px;			
				}
				@media screen and (min-width: $break-large) {
					padding-left: 375px;				
				}
			}
			.post-image {
				max-width: 495px;
				@media screen and (min-width: $break-medium) {
					width: 192px;
					float: left;				
				}
				@media screen and (min-width: $break-large) {
					width: 323px;				
				}
				img {
					@media screen and (max-width: $break-medium - 1px) {
						width: 100%;			
					}
				}
			}
			.post-date {
				@include fontsize(12px);
				font-weight: 500;	
				color: $color-blue;	
				@media screen and (min-width: $break-medium) {
					line-height: 1;			margin-bottom: 29px;	
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
					margin-top: 7px;
				}
			}	
			.excerpt  {
				@include fontsize(14px);
				@media screen and (min-width: $break-medium) {
					max-width: 85%;					
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
				}
			}
			.sub-title {
				@include fontsize(16px);
				margin-top: 4px;
				font-weight: 700;
				@media screen and (min-width: $break-large) {
					@include fontsize(18px);
				}
			}
			.icon-arrow-right {
				font-size: 13px;
				@media screen and (min-width: $break-medium) {
					font-size: 15px;				
				}
			}
		}
	}
}
section.admissions-table .container {
	text-align: center;
	h2,p, table {
		text-align: left;
	}
	table {
		margin-bottom: 28px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 45px;
		}
	}
}
body.our-stories-listing {
	header#page-title {
		@media screen and (min-width: $break-medium) {
			margin-bottom: 0;				
		}
	}
}
body.office-detail,
body.staff-detail {
	header#page-title {
		margin-bottom: 16px;
		@media screen and (min-width: $break-medium) {
		    margin-bottom: 42px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-bottom: 82px;				
		}
	}
}
body.news-detail {
	header#page-title {
		margin-bottom: 30px;
		@media screen and (min-width: $break-medium) {
		    margin-bottom: 42px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-bottom: 58px;				
		}
	}
	section.generic-content {
		figure {
			@media screen and (min-width: $break-large) {
			 	margin-bottom: 	83px;			
			 } 
		}
	}
}
body.staff-detail {
	header#page-title {
		h1 {
			margin-bottom: 5px;
		}
		.position {
			@include fontsize(18px);
			margin-top: 5px;	
			@media screen and (min-width: $break-medium) {
				@include fontsize(20px);
				margin-top: 16px;				
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(26px);
			}	
		}
	}
	section.detail-body-top {
		.row {
			@media screen and (min-width: $break-large) {
			    margin-bottom: 52px;				
			}
		}
	}
}
section.detail-body-top {
	.row {
		@media screen and (min-width: $break-medium) {
		    margin-bottom: 16px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-bottom: 11px;				
		}
	}
	img {
		@media screen and (min-width: $break-medium) {
			width: 192px;
			height: auto;
			float: left;			
		}
		@media screen and (min-width: $break-large) {
			width: 400px;				
		}
	}
	img + .column {
		@media screen and (min-width: $break-medium) {
			padding-left: 230px				
		}
		@media screen and (min-width: $break-large) {
		    padding-left: 461px;				
		}
	}
	table {
		@include fontsize(14px);
		margin-top: 20px;
		margin-bottom: 0;
		@media screen and (min-width: $break-medium) {
			@include fontsize(18px);
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(24px);
		}
		tbody {
			border-top: none;		
		}
		tr {
			background-color: transparent !important;						
			border-bottom: none;
			@media screen and (max-width: $break-medium - 1px) {	
				display: block;
				margin-bottom: 10px;	
			}
			&:first-child td {
				@media screen and (min-width: $break-medium) {
					padding-top: 0;				
				}
			}
			td {
				padding: 0;
				@media screen and (max-width: $break-medium - 1px) {
					display: block;
				}
				@media screen and (min-width: $break-medium) {
					padding-bottom: 10px;				
				}
				@media screen and (min-width: $break-large) {
				    padding-bottom: 20px;				
				}
			}
			td:first-child, th[scope="row"] {
				font-weight: 700;
				color: $color-blue;
				@media screen and (min-width: $break-medium) {
					@include fontsize(18px);
					padding-left: 0;
				    min-width: 109px;						
				}
				@media screen and (min-width: $break-large) {
					@include fontsize(24px);
				    min-width: 136px;				
				}
			}
		}
		a[href^="mailto:"] {
			border-bottom: none !important;
			box-shadow: none !important;
			transition: 0.3s !important;
			&:hover {
				background-color: transparent !important;
			}
			.icon-email {
				font-size: 20px;
			    vertical-align: middle;
			    position: relative;
			    top: -1px;
			    margin-right: 3px;
				@media screen and (min-width: $break-medium) {
					font-size: 26px;
				    margin-right: 7px;
				    vertical-align: middle;
				    position: relative;
				    top: -1px;				
				}	
			}
		}
	}
	.bordered:after {
		margin-top: 10px;
	}
	.links-wrapper {
		margin-top: 10px;
		@media screen and (min-width: $break-large) {
			margin-top: 20px;				
		}
		a.button {
			margin-bottom: 10px;
			padding: 6px 16px 6px;
			@media screen and (min-width: $break-medium) {
				display: inline-block;
				min-width: 0;
				margin-right: 7px;			
			}
			@media screen and (min-width: $break-large) {
				margin-right: 15px;	
				padding: 12px 18px 13px;			
			}
		}
	}
}
body.story-detail {
	blockquote {
		border: none !important;
		&:before {
			display: none !important;
		}
	}
}

/*edge and IE fallbacks*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	section.welcome-to .grid .image-overlay:before {
		display: block;
		content: '';
		background-color: rgba($color-blue, 0.6);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@supports (-ms-ime-align:auto) and (-webkit-text-stroke:initial) {
	section.welcome-to .grid .image-overlay:before {
		display: block;
		content: '';
		background-color: rgba($color-blue, 0.6);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
/*-----
	Base Styles 
	-----*/
html {
	background-color: $color-white; 
	* {
		box-sizing: border-box !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
body {
	@include fontsize(16px);
	font-family: 'Roboto', sans-serif;
	line-height: 24px;
	position: relative;
	max-width: 1600px;
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	@media screen and (min-width: $break-medium) {
		@include fontsize(16px);
		line-height: 30px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(18px);
	}
}

div.inner-body {
	overflow-x: hidden;
	position: relative;
}
h1, .h1 {
	@include fontsize(34px);
	font-weight: 900;	
	line-height: 1;
	letter-spacing: -0.43px;
	@media screen and (min-width: $break-medium) {
		@include fontsize(46px);
		letter-spacing: -0.58px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(72px);
		letter-spacing:-1px;
	}
}
h2, .h2 {
	@include fontsize(24px); 
	font-weight: 900;	
	line-height: 1.1;
	color: $color-blue;
	margin-bottom: 11px;
	letter-spacing:-0.56px;
	@media screen and (min-width: $break-medium) {
		@include fontsize(32px);
		margin-bottom: 35px;    
		margin-bottom: 9px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(40px);
		margin-bottom: 47px;
		margin-top: 35px;
	}
}
h3, .h3 {
	@include fontsize(22px);
	line-height: 1.1;
	font-weight: 700;
	@media screen and (min-width: $break-medium) {
		@include fontsize(28px);
		margin-bottom: 15px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(32px);
	}
}
h4, .h4 {
	@include fontsize(20px);
	font-weight: 700;
	line-height: 1.3;
	@media screen and (min-width: $break-medium) {
		@include fontsize(24px);
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(24px);
	}
}
h5, .h5 {
	@include fontsize(18px);
	font-weight: 700;
	line-height: 1.4;
	@media screen and (min-width: $break-medium) {
		@include fontsize(20px);
		margin-bottom: 16px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(22px);
	}
}
h6, .h6 {
	@include fontsize(18px);
	font-weight: 900;
	line-height: 1.4;
	text-transform: uppercase;
	@media screen and (min-width: $break-medium) {
		@include fontsize(18px);
		margin-bottom: 16px;
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(20px);
	}
} 
p {
	line-height: 1.55;    
	margin-top: 11px;
	@media screen and (min-width: $break-large) {
		max-width: 910px;				
	}
	&.large {
		@include fontsize(18px);
		line-height: 26px;
		@media screen and (min-width: $break-medium) {
			@include fontsize(18px);
			line-height: 28px;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(25px);
			line-height: 38px;
		}
	}
	&.small {
		@include fontsize(14px);
		line-height: 19px;
		@media screen and (min-width: $break-large) {
			@include fontsize(16px);
			line-height: 26px;
		}
	}
	+ { //ensures the header elements space accordingly
		h2, h3, h4, h5, h6 {
			margin-top: 35px;
			@media screen and (min-width: $break-large) {
				margin-top: 50px;
			}
		}
	}
}
button {
	background-color: transparent;
	margin: 0;
	padding: 0;
	border: none;
	appearance: none;
	user-select: none;
}
.button {
	@include fontsize(12px);
	display: inline-block;
	text-transform: uppercase;
	font-weight: 500;
	min-width: 241px;
	margin-left: auto;
	margin-right: auto;
	padding: 11px 10px 9px;    
	text-align: center;
	@media screen and (min-width: $break-medium) {
	    padding: 6px 20px 6px;	
	    max-width: 100%;	
	}
	@media screen and (min-width: $break-large) {
		@include fontsize(16px);
	  	padding: 14px 25px 11px;
	}		
	&.outline {
		border-width: 1px;
		border-style: solid;
		border-radius: 2px;
		font-weight: 500;
		letter-spacing: 0.53px;
		transition: 0.3s;
		&.black {
			border-color: #000;
			color: #000;
		}
		&.white {
			border-color: #fff;
			color: #fff;
		}
		&.yellow {
			border-color: $color-yellow;
			color: $color-yellow;
		}
		&:hover {
			text-decoration: none;
			&.black {
				border-color: $color-blue-2;
				background-color: $color-blue-2;
				color: #fff;
			}
			&.white {
				border-color: #fff;
				background-color: #fff;
				color: $color-blue-2;
			}
			&.yellow {
				border-color: $color-yellow;
			}
		}
	}
	&.solid {
		border-radius: 2px;
		transition: 0.3s;
		&.black {
			background-color: #000;
		}
		&.white {
			background-color: #fff;
		}
		&.yellow {
			background-color: $color-yellow;
			color: #000;
		}
		&:hover {
			text-decoration: none;
			&.yellow {
				background-color: $color-grey-4;
			}
		}
	}
	&.tag {

	}
}
hr {
	height: 2px;
}
figure, img {
	display: block;
	max-width: 100%;
	height: auto;
	&.float-right {
		margin-bottom: 15px;
		@media screen and (min-width: $break-medium) {
			float: right;
			max-width: 50%;	
			padding-left: 25px;			
		}
		@media screen and (min-width: $break-large) {
			padding-right: 42px;	
		}
	}
	&.float-left {
		margin-bottom: 15px;
		@media screen and (min-width: $break-medium) {
			float: left;
			max-width: 50%;
			padding-right: 25px;				
		}
		@media screen and (min-width: $break-large) {
			padding-right: 42px;			
		}
	}
}
picture {
	display: block;
}
figure {
	margin: 0;
	margin-bottom: 30px;
	&.photo {
	    margin-top: 31px;
		img {
			max-width: none;
			width: 100%;
		}
	}
	&.full-screen {
		margin-top: 30px;
		@media screen and (min-width: $break-large) {
			margin-bottom: 47px;
			img {
				width: 100%;
			}				
		}
	}
	&.full-image {
		@media screen and (min-width: $break-medium) {
			margin-top: 25px;
			margin-bottom: 35px;
		}
		@media screen and (min-width: $break-large) {
		    margin-top: 49px;	
			margin-bottom: 60px;			
		}
		img {
		}
	}
	&.align-right {
		@media screen and (min-width: $break-medium) {
			float: right;
			max-width: 50%;
			padding-left: 25px;
		}
		img {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
	figcaption {
		p {
			@include fontsize(14px);
			@media screen and (min-width: $break-medium) {
				margin-top: 7px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				margin-top: 12px;
			}
		}
	}
}
.embed-container { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%; 
} 
.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
}

blockquote {
	margin-top: 25px;
	margin-bottom: 25px;
	border-top: 2px solid $color-grey-3;
	border-bottom: 2px solid $color-grey-3;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	.container {
		padding-left: 45px;
		padding-top: 15px;
		padding-bottom: 20px;
		position: relative;
		padding-right: 45px;
		@media screen and (min-width: $break-medium) {
			padding-left: 70px;	
			padding-bottom: 30px;	    
			padding-top: 27px;    
			padding-right: 70px;		
		}
		@media screen and (min-width: $break-large) {
			padding-left: 183px;
		    padding-bottom: 56px;
		    padding-top: 43px;
		    padding-right: 50px;
		}
		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 0;
			width: 400px;
			height: 100%;
			transform: skewX(-3.4deg);
			opacity: 1;
			right: -368px;
			background-color: #0099cc;
			@media screen and (min-width: $break-medium) {
				transform: skewX(-6.4deg);
				right: -353px;
			}
		}
		span.icon-quote {
			position: absolute;
			left: 7px;
		    top: 32px;
			display: inline-block;
			transform: rotate(180deg);
			color: $color-blue;
			font-size: 15px;
			@media screen and (min-width: $break-medium) {
				font-size: 22px;
				left: 15px;	
				top: 44px;	
			}
			@media screen and (min-width: $break-large) {
				font-size: 26px;
				left: 122px;
				top: 63px;				
			}
		}
	}
	p {
		@include fontsize(25px);
		color: $color-blue;
		line-height: 1.33;
		position: relative;
		z-index: 1;
		margin-bottom: 8px;
		margin-top: 18px;
		font-weight: 100;
		@media screen and (min-width: $break-medium) { 
			@include fontsize(30px);
		    line-height: 1.35;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(46px);
			line-height: 1.35;          
		}
	}
	footer {
		@include fontsize(18px);    
		text-align: left;
		font-weight: 700;
		margin-top: 18px;
		@media screen and (min-width: $break-large) {
			@include fontsize(18px);        
		}
		@media screen and (min-width: $break-large) {    
			margin-top: 26px;
		}
	}
}
table {
	text-align: left;
	margin-bottom: 35px;
	width: 100%;
	@media screen and (min-width: $break-medium) {
		margin-bottom: 60px; 
	}
	@media screen and (min-width: $break-large) {
		margin-bottom: 80px;
	}
	&.no-bt {
		caption {
		    padding: 10px 0 4px;
		}
		thead {
			tr {
				background-color: transparent !important;
				th {					
					@media screen and (min-width: $break-medium) {
						padding: 13px 25px 13px;
					}
				}
			}
		}	
	}
	&.bt {
		@media screen and (max-width: $break-medium - 1px) {
			margin-left: -15px;
			margin-right: -15px;
			width: calc(100% + 30px);
		}
	}
	caption {
		@include fontsize(18px);	
		font-weight: 900;
		letter-spacing: -0.27px;
		line-height: 1.22;
		color: $color-blue;	
		text-align: left;
		padding: 10px 15px 25px;
		text-transform: uppercase;
	}
	thead {
		@include fontsize(15px);
		@media screen and (min-width: $break-large) {
		    border-bottom: 2px solid #3a3a3a;
		}
		@media screen and (min-width: $break-large) {
			@include fontsize(20px); 
			font-weight: 100;
		}
		tr {
			th {
				font-weight: normal;
				color: $color-blue;
				font-weight: 900;
				line-height: 1;
				@media screen and (min-width: $break-medium) {
					padding: 25px 25px 13px;
				}
				@media screen and (min-width: $break-large) {
					padding-left: 25px;
					line-height: 1.1;
				}
			}
		}
	}
	tbody {
		border-top: 1px solid #c9c9c4;
	}
	tr {
		border-bottom: 1px solid #c9c9c4;
		&:nth-child(even) {
			background: #f4f4f4;
		}
		td {
			font-weight: 100;
			padding-left: 25px;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-right: 10px;

			&::before {
				line-height: 1.25;
			}
		}
		td, th {
			vertical-align: top;
			padding:15px;	
			font-weight: normal;
			@media screen and (min-width: $break-medium) {
				padding: 20px;				
			}
			@media screen and (min-width: $break-large) {
				padding: 10px 15px 10px 25px;			
			}
		}
	}
}
form {
	@include clearfix();
	margin-top: 25px;
	margin-bottom: 25px;
	fieldset {
		border: none;
		margin: 8px 0 15px;
		padding: 0;
	}
	label, legend{
		font-weight: 600;
		margin-bottom: 10px;
		@media screen and (min-width: $break-medium) {
			margin-bottom: 4px;				
		}
	}
	label, textarea  {
		display: block;
	}
	textarea {
		width: 100%;
		margin-bottom: 27px;
		min-height: 168px;
		padding: 10px 14px 8px;
		font-weight: 100;
		max-width: 773px;
		appearance: none;
	}
	input {
		padding: 10px 14px 8px;
		margin-bottom: 20px;
		border: 1px solid $color-grey-6;
		border-radius: 4px;
		background-color: #fff;
		appearance: none;
		&[type="text"] {
			width: 100%;
			@media screen and (min-width: $break-medium) {
				max-width: 290px;
			}
		}
	}
	input[type="submit"],
	button[type="submit"] {
	}
}
select {
	width: 100%;
	height: 38px;
	font-size: 16px;
	background-size: 11px auto;
	padding: 4px 28px 8px 14px;
	margin-bottom: 20px;
	border: 1px solid $color-grey-6;
	border-radius: 4px;
	background-color: #fff;
	background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis;
    background: #fff url(images/icon-dropdown-arrows_black.png) no-repeat right 10px top 50%;
    background-size: 7px auto;
	@media screen and (min-width: $break-medium) {
		max-width: 290px;
	}
}
select::-ms-expand {
    display: none;
}
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
	position: absolute;
	left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
	@include fontsize(16px);
	position: relative;
	padding-left: 26px;
	cursor: pointer;
	font-weight: normal;
}

/* checkbox aspect */
[type="radio"] + label:before {
	content: '';
	position: absolute;
	left: 0; 
	top: 3px;
	width: 17px; 
	height: 17px;
	background: #fff;
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid $color-grey-6;
	@media screen and (min-width: $break-large) {
	    top: 6px;
	}
}
[type="radio"]:checked + label:before {
	border: 1px solid #000;
}
[type="radio"]:checked + label:after { 
	content: '';
	display: block;
	position: absolute;
	left: 4px; 
	top: 7px;
	width: 9px; 
	height: 9px;
	border-radius: 50%;
	box-sizing: border-box;
	background-color: #000;
	//border: 1px solid #000;
	@media screen and (min-width: $break-large) {
		top: 10px;
	}
}
[type="checkbox"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0; 
	top: 3px;
	width: 17px; 
	height: 17px;
	background: #fff;
	box-sizing: border-box;
	border: 1px solid $color-grey-6;
	color: #fff;
	border-radius: 2px;
	@media screen and (min-width: $break-large) {
	    top: 5px;
	}
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label .icon-checkmark {
	display: none;
}	
[type="checkbox"]:checked + label .icon-checkmark {
	position: absolute;
	left: 0; 
	top: 1px;
	padding-top: 1px;
	font-size: 11px;
	width: 16px; 
	height: 16px;
	color: #fff;
	border: 1px solid #000;
	text-align: center;
	box-sizing: border-box;
	background-color: #000;    
	font-size: 8px;    
	line-height: 13px;
	border-radius: 2px;
	@media screen and (min-width: $break-large) {
	    top: 6px;
	}
}
/* accessibility */
[type="checkbox"]:focus + label:before,
[type="checkbox"]:focus + label:after,
[type="radio"]:focus + label:before {
	outline: 1px dotted #212121;
	outline: 5px auto -webkit-focus-ring-color;
}
.accessible, .accessible-alt-text {
	@include element-invisible();
}
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 15px;
	color: #3a3a3a;
	letter-spacing: 0.63px;
	font-style: italic !important;
	@media screen and (min-width: $break-large) {
		font-size: 16px !important;						
	}
}
video {
	position: relative;
	display: block;
}
.skip-link {
	@include element-focusable();
	color: $color-black;
	text-align: center;
	text-decoration: none;
	display: block;
	margin: 0 auto;
	width: 100%;
	overflow: auto;
	z-index: 6000;
	max-width: 1600px;
	margin: 0 auto;
	&:hover {
		text-decoration: none;
	}
	&:active, &:focus {
		position: fixed !important;
		top: 0;
		left: 0;
		width: 100%;
		clip: auto;
		height: auto;
		background-color: #fff;

	}
}
.container {
	max-width: 1280px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
	@media screen and (min-width: $break-medium) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@media screen and (min-width: $break-large) {
		padding-left: 50px;
		padding-right: 50px;
	}
}
a {
	color: inherit;
	transition: 0.3s;
	text-decoration: none;
	font-weight: 700;
	.last-word {
		white-space: nowrap;
	}
}
.bordered {
	&:after {
		content: '';
		height: 2px;
		background-color: $color-off-white;
		display: inline-block;
		width: 100%;
	    margin: 28px auto 0;
	    @media screen and (min-width: $break-large) {
	    	margin-top: 50px;				
	    }
	}
}
.main-content {
	ul {
		padding-left: 0;
		@media screen and (min-width: $break-medium) {
		    padding-left: 23px;				
		}
		@media screen and (min-width: $break-large) {
		    margin-bottom: 23px;    
		    padding-left: 31px;				
		}
		li {
			padding-left: 25px;
			list-style: none;
			position: relative;
			margin-bottom: 9px;
			font-weight: normal;
			@media screen and (min-width: $break-medium) {
				margin-bottom: 3px;				
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 8px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: $color-grey-2;
				@media screen and (min-width: $break-medium) {
					top: 12px;				
				}
				@media screen and (min-width: $break-large) {
					top: 12px;
				}
			}
		}
	}
	ol {
		padding-left: 18px;
		@media screen and (min-width: $break-medium) {
		    padding-left: 39px;				
		}
		@media screen and (min-width: $break-large) {
			padding-left: 50px;				
		}
		li {
			padding-left: 9px;
		}
	}
	a {
		color: $color-blue-2;
		position: relative;
		transition: 0.3s all ease-out;
		&:not(.button) {			
		    box-shadow: inset 0 -6px 0 $color-grey-3;
    		transition: background .3s cubic-bezier(0.33, 0.66, 0.66, 1);
    		overflow-wrap: break-word;
		    word-break: break-word;
		    word-wrap: break-word;
		    &:hover {
				transition: 0.3s all ease-out;
				box-shadow: inset 0 -40px 0 $color-grey-3;
		    }
		}
		&:hover {
			text-decoration: underline;
			text-decoration: none;
			//background-color: $color-grey-3;
			color: $color-blue;
		}
	}
	p {
		a {
			font-weight: 700;
		}
	}
}
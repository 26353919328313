#alert-notification {
	@include clearfix();
	background-color: $color-red;
	color: $color-white;
	.container {
		position: relative;
		padding: 10px 20px 15px;
		max-width: 1600px;
		@media screen and (min-width: $break-medium) {
			padding: 10px 215px 17px 83px;
		}
		@media screen and (min-width: $break-large) {
			padding: 8px 290px 10px 142px;
		}
		.icon-alert {
			display: none;
			@media screen and (min-width: $break-medium) {
				position: absolute;
				font-size: 37px;
				display: block;
				left: 20px;
				top: 41px;
			}
			@media screen and (min-width: $break-large) {
				left: 37px;
				top: 29px;
				font-size: 63px;
			}
		}
		.title {
			@include fontsize(18px);
			margin-bottom: 6px;
			text-align: center;
			margin-top: 0;
			font-weight: 500;
			color: #fff;
			@media screen and (min-width: $break-medium) {
				@include fontsize(22px);
				margin-top: 20px;
				text-align: left;				
				margin-bottom: 8px;
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(28px);
				margin-bottom: 11px;
			}
		}
		.description {
			@include fontsize(14px);
			margin-top: 5px;
			font-weight: 300;
			line-height: 1.15;
			text-align: center;
			@media screen and (min-width: $break-medium) {
				text-align: left;				
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				margin-top: 0;
			}
		}
		.actions {
			padding-right: 50px;
			position: relative;
			max-width: 450px;
			margin: 0 auto;
			@media screen and (min-width: $break-medium) {
				max-width: 100%;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translate(0, -50%);	
				padding-right: 40px;			
			}
		}
		.button {
			@include fontsize(12px);
			width: 100%;
			display: block;
			text-align: center;
			padding: 9px 10px;
			font-weight: 500;
			max-width: 400px;
			@media screen and (min-width: $break-medium) {
			}
			@media screen and (min-width: $break-large) {
				@include fontsize(16px);
				padding: 12px 20px;
			}
		}
		button {
			font-size: 18px;
			line-height: 0;
			transition: 0.3s;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0,-50%);
			background-color: transparent;
			width: 22px;
			height: 22px;
			text-align: center;
			padding: 0;
			margin: 0;
			border: 2px solid #fff;
			border-radius: 2px;
			&:hover {
				color: #fff;
			}
			span.icon-close {
				font-size: 14px;
				display: inline-block;
				position: relative;
			    display: inline-block;
			    position: relative;
			    left: 0px;
			    top: 0px;
			}
			@media screen and (min-width: $break-large) {
				font-size: 40px;
			}
		}
	}
}
.header-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 5000;
	max-width: 1600px;
	left: 50%;
	transform: translate(-50%,0);	
	&.sticky {
		position: fixed;
		#masthead {
			@extend #masthead.header-active;
		}
	}
}
#masthead {
	background-color: rgba(#000, 0.72);
	transition: 0.3s;
	.positioner {
		@include clearfix();
		position: relative; 
		padding: 7px 10px;
		@media screen and (min-width: $break-medium) {
			padding: 12px 15px;				
		}
		@media screen and (min-width: $break-large + 76px) {
			padding: 18px 32px 0;	    
			position: relative;
    		z-index: 5000;			
		}
	}
	.logo {
		display: block;
		float: left;	
		margin-top: 3px;
		transition: 0.3s;
		&:hover {
			opacity: 0.8;
		}
		.white {
			position: absolute;
    		top: 10px;
			@media screen and (min-width: $break-medium) {
				top: 15px;		
			}
			@media screen and (min-width: $break-large + 76px) {
				top: 21px;					
			}			
		}
		.color {
			opacity: 0;
			transition: 0.3s;
		}
		img	{
			max-height: 30px;
			width: auto;
			@media screen and (min-width: $break-medium) {
				max-height: 33px;				
			}
			@media screen and (min-width: $break-large + 76px) {
				max-height: 41px;				
			}
		}
	}
	.menu-buttons {
		display: flex;
		flex-direction: row;
		float: right;
		#header-cta {
			@include fontsize(10px);
			height: 36px; 
			color: $color-yellow;
			border: 1px solid $color-yellow;
			border-radius: 1px;
			text-transform: uppercase;
			padding: 0 10px;
			transition: 0.3s;
			font-weight: 500;
			@media screen and (min-width: $break-medium) {
				@include fontsize(12px);	
				height: 44px;
				padding: 0 17px 0 13px;			
			}
			.icon-start {
				font-size: 14px;
				vertical-align: middle;
				padding-right: 9px;
				@media screen and (min-width: $break-medium) {
					font-size: 20px;
					padding-right: 14px;				
				}
			}			
			.mobile {
				@media screen and (min-width: $break-medium) {
					display: none;				
				}
			}
			.desktop {
				display: none;
				@media screen and (min-width: $break-medium) {
					display: inline-block;				
				}
			}
		}
		#search-control {
			order: 2;
			#toggle-search {
				width: 36px;
				height: 36px;
				margin-left: 3px;
				margin-right: 3px;
				color: #fff;
				border: 1px solid #fff;
				border-radius: 1px;
				transition: 0.3s;
				@media screen and (min-width: $break-medium) {
					width: 44px;
					height: 44px;				
				}
				@media screen and (min-width: $break-large + 76px) {
					margin-left: 8px;				
				}
				.icon-search {
					vertical-align: middle;
					display: inline-block;
				}
				&.toggled-on {
					background-color: $color-blue-2;
					color: #fff;
					&:hover {
						color: #fff;
					}
					.icon-search:before {
						@extend .icon-close:before;
					}
				}
			}
		}
		nav {
			@media screen and (max-width:  $break-large + 75px) {
				order: 3;				
			}
		}
		#main-menu-toggle {
			width: 36px;
			height: 36px;
			color: #fff;
			border: 1px solid #fff;
			border-radius: 1px;
			transition: 0.3s;
			@media screen and (min-width: $break-medium) {
				width: 94px;
				height: 44px;				
			}
			@media screen and (min-width: $break-large + 76px) {
				display: none;				
			}
			&.toggled-on:hover {
				color: #fff;
			}
			.icon-menu {
				vertical-align: middle;
				display: inline-block;	
				font-size: 14px;
			}
			.desktop {
				@include fontsize(12px);
				font-weight: 500;	
				text-transform: uppercase;	
				margin-left: 10px;
				@media screen and (max-width: $break-medium - 1px) {
					display: none;					
				}
			}
		}
	}
	.search-container {
		display: none;
	}
	#header-menu-cta {
		@media screen and (min-width: $break-large + 76px) {
			display: block;				
		}
		.header-menu-cta-toggle {
			@include fontsize(15px);
			text-transform: uppercase;		
			font-weight: 500;
			display: block;
			padding: 9px 13px;
			height: 44px;
			color: #fff;
			transition: 0.3s;	
			margin-right: 17px;
			@media screen and (max-width: $break-large + 76px - 1px) {
				display: none;				
			}
			&.toggled-on .icon-arrow-dropdown { 
				transform: rotate(180deg);
			}
			&.toggled-on + .header-menu-cta-content {
				@media screen and (min-width: $break-large + 76px) {
					display: flex;
					animation: fade-in 0.1s ease forwards;
				}
				@media screen and (min-width: $break-large + 76px) {
					@for $i from 0 through 6 {
					   .callout:nth-child(#{$i}) {
							position: relative;
					    	animation: fade-in 0.06s ($i * 0.1s) ease forwards;
					    }
					}				
				}
			}
			.icon-arrow-dropdown {
				font-size: 7px;
				position: relative;
				top: -2px;
				margin-left: 5px;
				display: inline-block;
			}
		}
		.header-menu-cta-content {
			margin: 0 16px;
			padding-bottom: 80px;
			z-index: 4;
			@media screen and (min-width: $break-medium) {
				@include clearfix();
				margin-left: -5px;
				margin-right: -5px;	
				display: flex;
				flex-direction: row;
				padding-left: 16px;
				padding-right: 16px;			
			}
			@media screen and (min-width: $break-large + 76px) {
				background-color: rgba($color-blue, 0.95);
				display: none;
				position: absolute;
				top: 81px;
				width: 100%;
				left: 0;
				color: #fff;
				padding-top: 80px;
				padding-left: 125px;
				padding-right: 125px;
				margin: 0;				
			}
			.callout {
				opacity: 0;
				border: 1px solid rgba(255,255,255,0.40);
				border-radius: 2px;
				padding: 24px 24px 20px;	
				margin-bottom: 8px;	
				@media screen and (min-width: $break-medium) {
					width: calc(33.33% - 10px);
					margin-left: 5px;
					margin-right: 5px;
				}	
				@media screen and (min-width: $break-large + 76px) {
					width: calc(33.33% - 28px);
					margin-left: 14px;
					margin-right: 14px;
					margin-bottom: 0;
				}	
			}
			h2 {
				@include fontsize(16px);
				text-transform: uppercase;
				color: $color-yellow;
				font-weight: 700;
				margin: 0;
			}
			#google_translate_element {
				display: none;
			}
			ul {
				padding-left: 0;
				@media screen and (min-width: $break-medium) {
					margin-top: 10px;
				}
				li {
					list-style: none;
				}
			}
			a {
				@include fontsize(20px);
				color: #fff;
				font-weight: normal;
				color: $color-off-white;
				letter-spacing: 0.84px;
				padding: 10px 0;
				display: block;
				@media screen and (min-width: $break-medium) {
					padding: 7px 0;				
				}
				&:hover {
					color: $color-yellow;
					text-decoration: underline;
				}
			}
		}
	}
	.header-cta-body-nav {
		order: 1 !important;
	}
	.header-cta-body {
		display: none;
		background-color: $color-blue;
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vh - 50px);
		color: $color-off-white;
		z-index: 4;
		@media screen and (min-width: $break-medium) {
			top: 68px;				
		}
		@media screen and (min-width: $break-large + 76px) {
			top: 80px;
			height: auto;
		}
		.scrollable {
			padding-bottom: 50px;
			@media screen and (min-width: $break-large + 76px) {
				max-height: calc(100vh - 80px);
				overflow-y: scroll;
				margin-right: -17px;							
			}
		}
		.container {
			opacity: 0;	
			padding-top: 15px;
			@media screen and (min-width: $break-medium) {
				padding-left: 16px;
				padding-right: 16px;	
			}
		}
		h2 {
			@include fontsize(28px);
			width: 100%;
			text-align: center;
			max-width: 221px;
			margin: 25px auto 25px;
			color: #fff;
			@media screen and (min-width: $break-medium) {				
				@include fontsize(30px);
				max-width: 100%;
				margin-bottom: 58px;				
			}
			@media screen and (min-width: $break-large + 76px) {			
				@include fontsize(36px);
			}
		}
		.links {
			width: 100%;
			@media screen and (min-width: $break-medium) {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;				
			}
			a {
				@include fontsize(14px);
				font-weight: normal;
				display: block;
				border: 1px solid rgba(255,255,255,0.40);
				border-radius: 2px;
				padding: 13px 10px;
				min-height: 63px;	
				line-height: 1.25;
				margin-bottom: 3px;
				opacity: 0;	
				@media screen and (min-width: $break-medium) {
					@include fontsize(16px);
					width: calc(50% - 5px);
					margin-bottom: 10px;
					min-height: 84px;	
					padding: 24px 20px;
					&:nth-child(odd) {
						margin-right: 5px;
					}	
					&:nth-child(even) {
						margin-left: 5px;
					}				
				}
				@media screen and (min-width: $break-large + 76px) {
					width: calc(33.333% - 4.5px);
					&:nth-child(3n+1) {
						margin-left: 0 !important;
						margin-right: 0 !important;
					}
					&:nth-child(3n+2) {
						margin-left: 6.5px !important;
						margin-right: 6.5px !important;
					}
					&:nth-child(3n+3) {
						margin-left: 0 !important;
						margin-right: 0 !important;
					}	
				}
				&:hover {
					border-color: $color-yellow;
					color: $color-yellow;
					box-shadow: inset 1px 1px 0 $color-yellow;
				}
			}
		}

		@at-root .menu-active {
			@media screen and (max-width: $break-large + 76px - 1px) {
				overflow: hidden;								
			}
			#masthead {
				#header-cta.toggled-on {
					background-color: $color-yellow;
					color: #000;
				}
				#header-cta.toggled-on + .header-cta-body {
					display: block;
					height: calc(100vh - 50px);
					width: 100%;
					overflow: hidden;
					@media screen and (max-width: $break-large + 76px - 1px) {
						overflow-y: scroll !important;
						-webkit-overflow-scrolling: touch;
					}
					@media screen and (min-width: $break-large + 76px) {						
						height: auto !important;				
					}
					.container {	
						display: block;
						animation: fade-in 0.1s ease forwards;
						@for $i from 0 through 20 {
						    a:nth-child(#{$i}) {
						    	animation: fade-in 0.1s ($i * 0.02s) ease forwards;
						    }
						}
					}
				}
			}
		}
	}
	.menus-wrapper {   
		z-index: 4;
		@media screen and (min-width: $break-large + 76px) {
			display: block;				
		}
		@media screen and (max-width: $break-large + 76px - 1px) {
			top: 68px;							
			display: none;
			position: absolute;
			left: 0;
		}
		@media screen and (max-width: $break-medium - 1px) {
			top: 50px;							
		}
		.menus-container {
			@media screen and (max-width: $break-large + 76px - 1px) {
				background-color: $color-blue;								
			}
			@media screen and (min-width: $break-large + 76px) {
				display: flex;
				flex-direction: row;				
			}
		}

		@at-root .menu-active {
			@media screen and (max-width: $break-large + 76px - 1px) {
				overflow: hidden;								
			}
			#masthead {
				#main-menu-toggle.toggled-on {
					background-color: $color-blue-2;
					color: #fff;
					.icon-menu:before {
						@extend .icon-close:before;
					}
				}
				#main-menu-toggle.toggled-on + .menus-wrapper {
					display: block;
					height: calc(100vh - 50px) !important;
					width: 100% !important;

					@media screen and (max-width: $break-large + 76px - 1px) {
						overflow-y: scroll !important;
						-webkit-overflow-scrolling: touch;
					}
					@media screen and (min-width: $break-large + 76px) {
						height: auto !important;
					}
					.main-menu > ul {
						@for $i from 0 through 6 {
						   > li:nth-child(#{$i}) {
						    	animation: fade-in 0.01s ($i * 0.02s) ease forwards;
						    }
						}
					}
					#header-menu-cta {			    		
						@for $i from 0 through 6 {
						   .callout:nth-child(#{$i}) {
						    	animation: fade-in 0.06s ($i * 0.1s) ease forwards;
						    }
						}
					}
				}
			}
		}
	}
	.main-menu {
		@media screen and (max-width: $break-large + 76px - 1px) {
			padding: 34px 16px 19px 16px;							
		}
		@media screen and (min-width: $break-medium) {
			padding-top: 61px;
			padding-bottom: 15px;				
		}
		@media screen and (min-width: $break-large + 76px) {
			padding-top: 0;
			padding-bottom: 0;				
		}
		a {
			color: #fff;
		}
		ul.sub-menu {
			display: none;
		}
		button.toggle {
			@media screen and (min-width: $break-large + 76px) {
				display: none;				
			}
		}
		ul {
			padding-left: 0;
			li {
				list-style: none;
			}
		}
		> ul {
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 0;
			padding-top: 5px;
			@media screen and (min-width: $break-large + 76px) {
				padding-top: 0;				
			}			
			> li {
				position: relative;
				list-style: none;
				padding-left: 42px;
				margin-bottom: 20px;
				@media screen and (min-width: $break-medium) {
					opacity: 0;
					padding-left: 106px;	
					margin-bottom: 43px;
				}
				@media screen and (min-width: $break-large + 76px) {
					position: static;
					display: inline-block;	
					opacity: 1;
					padding: 0;
					margin: 0;
					position: initial;	
					margin-left: -3px;	
				}
				&.fadeOut > ul.sub-menu {
				    display: block !important;
				    opacity: 1 !important;
				}
				&:before {
					display: none;
				}
				&.expanded {
					> a {
						color: $color-yellow;
					}
					> button {
						background-color: $color-yellow;
						color: #000;
						&:hover {
							color: #000;
						}
						.icon-light-expand:before {
							content: "\e92d";
						}
					}
				}
				&.nav-focus {
					ul.sub-menu {
						@media screen and (min-width: $break-large + 76px) {
							display: block;
							animation: fade-in 0.1s ease forwards;
						}
					}
					> a:before {
						height: 5px;
					}
				}
				> a {
					font-weight: 500;
					font-size: 24px;
					color: #fff;
					letter-spacing: 1px;
					line-height: 28px;
					text-align: left;
					text-transform: uppercase;
					@media screen and (min-width: $break-medium) {
						@include fontsize(30px);
					}
					@media screen and (min-width: $break-large + 76px) {
						@include fontsize(15px);
						text-transform: uppercase;
						font-weight: 500;
						display: block;
						padding: 10px 13px 25px;
						color: #fff;
						position: relative;
					}
					&:hover {
						color: $color-yellow;
					}
					&:before {
						@media screen and (min-width: $break-large + 76px) {
							display: block;
							content: '';
							height: 0;
							width: calc(100% - 26px);
							position: absolute;
							left: 50%;
							transform: translate(-50%,0);
							bottom: 0;
							transition: 0.3s;
							background-color: $color-blue-2;
						}
					}	
					.icon-arrow-light-right { 
						font-size: 16px;
						position: relative;
						top: -2px;
						@media screen and (min-width: $break-medium) {
							font-size: 19px;				
						}
						@media screen and (min-width: $break-large + 76px) {
							display: none;
						}
					}
				}
				> button {
					position: absolute;
					left: 0;
					top: 0;
					color: #fff;
					width: 25px;
					height: 25px;
					font-size: 12px;
					line-height: 25px;
					text-align: center;
					vertical-align: middle;
					border: 1px solid rgba(#fff,0.30);
					border-radius: 1px;
					transition: 0.3s;
					@media screen and (min-width: $break-medium) {
						width: 36px;
						height: 36px;
						font-size: 18px;
						line-height: 36px;
						left: 44px;
						top: -5px;					
					}
					&:hover {
						color: $color-yellow;
						border-color: $color-yellow;
					}
				}
				> ul > li:first-child {
					text-transform: uppercase;
					@media screen and (max-width: $break-large + 76px - 1px) {
						display: none;				
					}
					.icon-arrow-light-right {						
						@media screen and (min-width: $break-large + 76px) {
							font-size: 25px;
							top: 0;
							margin-left: -3px;
						}
					}
				}
				ul {
					padding-top: 6px;
					margin-bottom: 35px;
					@media screen and (min-width: $break-large + 76px) {
						padding-left: 128px;
						padding-right: 128px;										
					}
					@media screen and (min-width: $break-medium) {
						padding-top: 8px;				
					}
					@media screen and (min-width: $break-large + 76px) {
						background: rgba($color-blue, .95);
						// display: none;
						// opacity: 0;
						columns: 2;		
						padding-top: 26px;
						column-gap: 0px;			
						position: absolute;
						top: 80px;
						left: 0;
						width: 100%;	
						padding-top: 153px;
						padding-bottom: 81px;				
					}
					li {
						@media screen and (min-width: $break-large + 76px) {
							break-inside: avoid-column;
							page-break-inside: avoid;	
							padding-right: 50px;
							padding-bottom: 9px;				
						}
						&:first-child {
							@media screen and (min-width: $break-large + 76px) {
								position: absolute;
								top: 82px;
								left: 128px;				
							}
							> h2 {
								margin: 0;
								a {
									@media screen and (min-width: $break-large + 76px) {
										@include fontsize(36px);		
										font-weight: 700;
										letter-spacing: 1.5px;	
									}
								}	
							}
						}
					}
					a {
						font-weight: normal;
						display: block;
						padding: 7px 0;
						font-size:16px;
						color: $color-off-white;
						letter-spacing: 0.67px;
						text-align: left;
						border-bottom: 1px solid transparent;
	    				box-shadow: inset 0 -2px 0 transparent;
						@media screen and (min-width: $break-medium) {
							font-size: 20px;
							padding-bottom: 8px;				
						}
						@media screen and (min-width: $break-large + 76px) {
							@include fontsize(22px);	
							padding: 6px 0 5px;		
							display: inline;				
						}
						&:hover {
							color: $color-yellow;
							text-decoration: none;
							border-bottom: 1px solid transparent;
		    				box-shadow: inset 0 -2px 0 $color-yellow;
						}
					}
				}
			}
		}
	}
	#search-control {
		.search-container {
			display: none;
			position: absolute;
			left: 0;
			top: 50px;
			width: 100%;    
			z-index: 4;
			@media screen and (min-width: $break-medium) {
				top: 68px;				
			}
			@media screen and (min-width: $break-large + 76px) {
				max-width: 600px;	
				left: auto;
				right: 0;
				top: 81px;			
			}
			#header-search {
				margin-top: 0;
				margin-bottom: 0;
				background-color: rgba($color-blue, 0.95);
				transition: opacity 0.6s;
				padding: 12px 10px;
				position: relative;
				padding-right: 50px;
				@media screen and (min-width: $break-medium) {
					padding: 44px 85px;				
				}
			}
			#search-field {
				margin-bottom: 0;
				height: 50px;
				width: 100%;
				opacity: 0; 
				border-radius: 2px !important;
				appearance: none !important;
				-webkit-appearance: none !important;
			} 
			#close-header-search {
				position: absolute;
				left: 7px;
				top: 50%;
				transform: translate(0,-50%);
				color: rgba(#fff, 0.7);
				width: 36px;
				height: 36px;
				font-size: 18px;
				line-height: 22px;
				z-index: 1; 
				@media screen and (max-width: $break-medium - 1px) {
					display: none;				
				}
				@media screen and (min-width: $break-medium) {
					left: 37px;				
				}
			}
			button[type="submit"] {
				position: absolute;
				right: 7px;
				top: 50%;
				transform: translate(0,-50%);
				width: 36px;
				height: 36px;
				color: #fff;	
				font-size: 22px;
				line-height: 22px; 
				opacity: 0;
				min-width: 0;
				padding: 0;
				@media screen and (min-width: $break-medium) {
					right: 37px;				
				}
			}
		}
		.toggled-on + .search-container {		
			display: block;
			animation: fade-in 0.1s ease forwards;
			#search-field { 
				animation: fade-in 0.1s 0.1s ease forwards;
			}
			button[type="submit"] { 
				animation: fade-in 0.1s 0.2s  ease forwards;
			}
		}

	}
	&.is-active,
	&.header-active {
		background-color: #fff;
		.logo {
			.white {
				display: none;
				opacity: 0;
			}
			.color {
				display: block;
				opacity: 1;
			}
		}
		#header-cta {
			border-color: $color-blue-2;
			color: $color-blue-2;
			&:hover {
				border-color: $color-yellow;
				color: #000;
			}
		}
		#search-control #toggle-search,
		#main-menu-toggle {
			border-color: $color-blue;
			color: $color-blue;
			&:hover {
				border-color: $color-blue-2;
				color: $color-blue-2;
			}
			&.toggled-on {
				color: #fff;
			}
		}
		#header-menu-cta .header-menu-cta-toggle,
		.main-menu > ul > li > a {
			@media screen and (min-width: $break-large + 76px) {
				color: $color-blue; 
			}
			&:hover {
				@media screen and (min-width: $break-large + 76px) {
					color: $color-blue-2;									
				}
			}
		}
	}
}
.goog-te-menu-value {
	display: none;
}
#google_translate_element .goog-te-gadget {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    white-space: nowrap;
    .goog-te-gadget-simple {
    	background-color: transparent !important;
    	border: none !important;
    	font-size: !important;
    	padding: 7px 0;
    }
    .goog-te-gadget-icon {
		display: none;
    }
    .goog-te-menu-value {

	    span:nth-child(n+2) {
	    	display: none !important;
	    }
    }
    img {
    	display: none;
    }
} 
/*edge and IE fallbacks*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	#masthead #header-menu-cta .header-menu-cta-content .callout {
		opacity: 1 !important;
		animation: none !important;
	}
}